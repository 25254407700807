<div class="ibox float-e-margins">
  <div class="ibox-content no-borders">
    <div>
      <h4>Derniers Rapports saisis</h4>
      <div class="sk-loading" *ngIf="loading">
        <div class="spiner-example">
          <div class="sk-spinner sk-spinner-wave">
            <div class="sk-rect1"></div>
            <div class="sk-rect2"></div>
            <div class="sk-rect3"></div>
            <div class="sk-rect4"></div>
            <div class="sk-rect5"></div>
          </div>
        </div>
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let a of reports">
          <span class="text-capitalize small">
            <em class="fal fa-clock"></em>&nbsp;
            {{ a.alarmTime | date: 'EEEE dd MMM à HH:mm' }}
          </span>
          <p>
            <a [routerLink]="['/statistics', 'alarm', a.rapport]">
              {{ a.what }}
            </a>
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
