import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Created by dgo on 06.01.18
 */
@Injectable()
export class PermissionsService {
  constructor(private http: HttpClient) {}

  findAllRoles() {
    return this.http.get('/api/admin/permissions/roles');
  }

  assign(assign: any) {
    return this.http.patch('/api/admin/permissions/assign', assign);
  }

  unassign(unassign: any) {
    return this.http.patch('/api/admin/permissions/unassign', unassign);
  }
}
