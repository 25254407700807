import {Directive, HostListener} from '@angular/core';

declare var jQuery: any;

@Directive({
    selector: '[appCloseMenuOnClick]',
    standalone: false
})
export class CloseMenuOnClickDirective {
  @HostListener('click') onClick() {
    // Hack to close the mini bar if open (mobile)
    jQuery('body').removeClass('mini-navbar');
  }
}
