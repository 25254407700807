import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GoogleCalendarsWidgetComponent } from './piquets-widget/google-calendars-widget.component';

@NgModule({
  declarations: [GoogleCalendarsWidgetComponent],
  exports: [GoogleCalendarsWidgetComponent],
  imports: [CommonModule, RouterModule]
})
export class PluginGoogleCalendarModule {
}
