import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../model/user';
import {Version} from '../../model/version';
import {Announces} from '../../plugin-announce/model/announces';
import {MessageBellService} from '../../services/message-bell.service';

@Component({
    selector: 'mir-shortcuts-bar',
    templateUrl: './shortcuts-bar.component.html',
    styleUrls: ['./shortcuts-bar.component.css'],
    standalone: false
})
export class ShortcutsBarComponent implements OnInit {

  @Input()
  user: User;

  @Input()
  version: Version;

  showToday = false;

  announces: Announces;

  private announceSubscription;

  public constructor(
    private bellService: MessageBellService
  ) {
  }

  public ngOnInit(): any {


    if (this.version.isAuthorized('ANNOUNCE')) {
      this.announceSubscription = this.bellService.announcesObservable.subscribe(
        (announces: Announces) => {
          this.announces = announces;
        }
      );
      this.bellService.reload();
    }
  }

  public ngOnDestroy(): any {

    if (this.announceSubscription) {
      this.announceSubscription.unsubscribe();
    }
  }

}
