<nav class="navbar-default navbar-static-side" role="navigation" *ngIf="user">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="profile-element" dropdown>
          <img [src]="getLogo()" alt="image" width="80%" />
        </div>

        <div class="logo-element">MIR</div>
      </li>

      <li>
        <a [routerLink]="['/']" aria-expanded="false">
          <i class="far fa-home fa-fw"></i>
          <span class="nav-label"> Accueil</span>
        </a>
      </li>
      <li>
        <a aria-expanded="false" class="has-arrow" role="button" title="Profil">
          <i class="far fa-user-alt fa-fw"></i>
          <span class="nav-label"> Mon profil</span>
        </a>
        <ul class="nav nav-second-level">
          <li
            *ngIf="version?.isAuthorized('CONVOCATION') && user.isAuthorized('OP_CONVOCATION')"
          >
            <a [routerLink]="['/convocations']" appCloseMenuOnClick>
              <i class="fal fa-inbox-in"></i> Mes Convocations
            </a>
          </li>
          <ng-container *ngIf="version?.isAuthorized('FORMULAIRES')">
            <li *ngIf="user.isAuthorized('OP_FORM_READ')">
              <a [routerLink]="['/formulaires/absence']" appCloseMenuOnClick>
                <i class="fas fa-bullhorn"></i> Excuses et absences
              </a>
            </li>
            <li *ngIf="user.isAuthorized('OP_FORM_CREATE')">
              <a [routerLink]="['/formulaires/new']" appCloseMenuOnClick>
                <i class="fas fa-file-signature"></i> Heures à solder
              </a>
            </li>
            <li>
              <a
                *ngIf="user.isAuthorized('OP_FORM_CREATE')"
                [routerLink]="['/formulaires']"
                appCloseMenuOnClick
              >
                <i class="far fa-star"></i> Mes heures à solder
              </a>
            </li>
          </ng-container>
          <li *ngIf="version.sdis.ecaPortal">
            <a
              appCloseMenuOnClick
              href="https://mypassword.118-vaud.ch/"
              target="_blank"
              title="Changement du mot de passe ECA"
            >
              <i class="fas fa-key fa-fw"></i>
              Changer mot de passe
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="user.isAuthorized('ROLE_SAPEUR')">
        <a
          aria-expanded="false"
          class="has-arrow"
          role="button"
          title="Convocation"
        >
          <i class="far fa-fire fa-fw"></i>
          <span class="nav-label"> Mon SDIS</span>
        </a>
        <ul class="nav nav-second-level">
          <li *ngIf="version?.isAuthorized('ANNOUNCE')">
            <a [routerLink]="['/annonce']" appCloseMenuOnClick>
              <i class="fal fa-bell fa-fw"></i> Annonces
            </a>
          </li>
          <li
            *ngIf="version?.isAuthorized('ANNUAIRE') && user.isAuthorized('OP_ANNUAIRE')"
          >
            <a [routerLink]="['/annuaire']" appCloseMenuOnClick>
              <i class="fal fa-users fa-fw"></i> Annuaire
            </a>
          </li>

          <li
            *ngIf="version?.isAuthorized('MSGR') && user.isAuthorized('OP_MSGR')"
          >
            <a [routerLink]="['/msgr']" appCloseMenuOnClick>
              <i class="fal fa-pager fa-fw"></i> CTA
            </a>
          </li>

          <li
            *ngIf="version?.isAuthorized('MATERIEL') && user.isAuthorized('OP_BOOKING_READ')"
          >
            <a
              [queryParams]="{'ecawin': true}"
              [routerLink]="['/booking/calendar']"
              appCloseMenuOnClick
            >
              <i class="fas fa-calendar-alt fa-fw"></i> Calendrier
            </a>
          </li>

          <li
            *ngIf="version?.isAuthorized('STATISTICS') && user.isAuthorized('OP_WEBEASY_REPORTS')"
            title="Statistiques"
          >
            <a aria-expanded="false" class="has-arrow" role="button">
              <i class="fas fa-chart-line fa-fw"></i> Interventions
            </a>
            <ul class="nav nav-third-level">
              <li *ngIf="user.isAuthorized('OP_WEBEASY_REPORTS')">
                <a
                  *ngIf="user.nip"
                  [routerLink]="['/statistics/fireman', user.nip]"
                  appCloseMenuOnClick
                  ><i class="far fa-user-chart"></i> Mes interventions</a
                >
              </li>
              <li *ngIf="user.isAuthorized('OP_WEBEASY_REPORTS')">
                <a [routerLink]="['/statistics']"
                  ><i appCloseMenuOnClick class="far fa-chart-line"></i>
                  Rapports</a
                >
              </li>
              <li *ngIf="user.isAuthorized('OP_WEBEASY_HALL_OF_FAME')">
                <a [routerLink]="['/statistics/hall-of-fame']"
                  ><i appCloseMenuOnClick class="far fa-trophy"></i>
                  Classement</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <!--      <li *ngIf="version?.isAuthorized('MAINTENANCE')">-->
      <!--        <a-->
      <!--          aria-expanded="false"-->
      <!--          class="has-arrow"-->
      <!--          -->
      <!--          role="button"-->
      <!--          title="Roulage"-->
      <!--        >-->
      <!--          <i class="far fa-wrench fa-fw"></i>-->
      <!--          <span class="nav-label"> Maintenance</span>-->
      <!--        </a>-->
      <!--        <ul class="nav nav-second-level">-->
      <!--          <li>-->
      <!--            <a [routerLink]="['/maintenance/control']" appCloseMenuOnClick>-->
      <!--              <i class="fas fa-tasks"></i> Contrôler le matériel-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a [routerLink]="['/maintenance/material']" appCloseMenuOnClick>-->
      <!--              <i class="fas fa-hammer"></i> Gérer le matériel-->
      <!--            </a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->

      <li
        *ngIf="version?.isAuthorized('ROULAGE') && user.isAuthorized('OP_BOOKING_READ')"
      >
        <a
          aria-expanded="false"
          class="has-arrow"
          role="button"
          title="Roulage"
        >
          <i class="far fa-steering-wheel fa-fw"></i>
          <span class="nav-label"> Roulages</span>
        </a>
        <ul class="nav nav-second-level">
          <li *ngIf="user.isAuthorized('OP_BOOKING_CREATE')">
            <a [routerLink]="['/roulage/planifier']" appCloseMenuOnClick>
              <i class="fas fa-calendar-plus"></i> Planifier un roulage
            </a>
          </li>
          <li *ngIf="user.isAuthorized('OP_BOOKING_CREATE')">
            <a [routerLink]="['/roulage/saisir-rapport']" appCloseMenuOnClick>
              <i class="fas fa-file-signature"></i> Saisir rapport
            </a>
          </li>
          <li>
            <a
              *ngIf="user.isAuthorized('OP_BOOKING_CREATE')"
              [routerLink]="['/roulage/mes-roulages']"
              appCloseMenuOnClick
            >
              <i class="far fa-star"></i> Mes roulages
            </a>
          </li>

<!--          <li *ngIf="user.isAuthorized('OP_BOOKING_CREATE')">-->
<!--            <a [routerLink]="['/roulage/rapports']" appCloseMenuOnClick>-->
<!--              <i class="far fa-location-arrow"></i> Roulages {{user.site?.name}}-->
<!--            </a>-->
<!--          </li>-->

          <li>
            <a [routerLink]="['/roulage/rapports-sdis']" appCloseMenuOnClick>
              <i class="far fa-list fa-fw"></i> Roulages SDIS
            </a>
          </li>
          <li *ngIf="user.isAuthorized('OP_ROULAGE_ADMIN')">
            <a [routerLink]="['/roulage/formation-sdis']" appCloseMenuOnClick>
              <i class="fas fa-tachometer-alt"></i> Formations
            </a>
          </li>
        </ul>
      </li>
      <li
        *ngIf="version?.isAuthorized('MATERIEL') && user.isAuthorized('OP_BOOKING_READ')"
      >
        <a
          aria-expanded="false"
          class="has-arrow"
          role="button"
          title="Commandes matériel"
        >
          <i class="far fa-truck fa-fw"></i>
          <span class="nav-label"> Matériel</span>
        </a>
        <ul class="nav nav-second-level">
          <li *ngIf="user.isAuthorized('OP_BOOKING_CREATE')">
            <a [routerLink]="['/booking/new']" appCloseMenuOnClick>
              <i class="fal fa-plus fa-fw"></i> Créer commande
            </a>
          </li>

          <li>
            <a
              *ngIf="user.isAuthorized('OP_FAILURE_CREATE')"
              [routerLink]="['/failure/new']"
              appCloseMenuOnClick
            >
              <i class="far fa-comment-alt-exclamation fa-fw"></i>
              Annonce matériel
            </a>
          </li>

          <li>
            <a
              *ngIf="user.isAuthorized('OP_BOOKING_CREATE')"
              [routerLink]="['/booking/my']"
              appCloseMenuOnClick
            >
              <i class="far fa-star fa-fw"></i> Mes commandes
            </a>
          </li>

          <li>
            <a [routerLink]="['/booking/all']" appCloseMenuOnClick>
              <i class="far fa-list fa-fw"></i> Toutes les commandes
            </a>
          </li>

          <li>
            <a
              [queryParams]="{'events': true}"
              [routerLink]="['/booking/calendar']"
              appCloseMenuOnClick
            >
              <i class="far fa-calendar-alt fa-fw"></i> Calendrier
            </a>
          </li>

          <li *ngIf="user.isBookingValidator()">
            <a
              [routerLink]="['/booking/settings/resources']"
              appCloseMenuOnClick
            >
              <i class="far fa-truck fa-fw"></i> Matériels
            </a>
          </li>
          <li *ngIf="user.isBookingValidator()">
            <a [routerLink]="['/booking/settings/types']" appCloseMenuOnClick>
              <i class="far fa-palette fa-fw"></i> Types et couleurs
            </a>
          </li>
        </ul>
      </li>

      <li
        [style.display]="version?.isAuthorized('APR') && user.isAuthorized('OP_APR_READ') ? '' : 'none'"
        title="APR"
      >
        <a class="has-arrow" role="button" title="APR">
          <i class="far fa-fire-extinguisher fa-fw"></i>
          <span class="nav-label"> APR</span>
        </a>
        <ul class="nav nav-second-level">
          <li>
            <a
              [routerLink]="['/apr']"
              appCloseMenuOnClick
              aria-expanded="true"
              *ngIf="user.isAuthorized('OP_APR_READ')"
            ><i class="far fa-table"></i> Tableau</a
            >
          </li>
          <li>
            <a
              *ngIf="user.isAuthorized('OP_APR_READ')"
              [routerLink]="['/apr/journal']"
              appCloseMenuOnClick
              aria-expanded="true"
              ><i class="far fa-list"></i> Journal</a
            >
          </li>
          <li>
            <a
              *ngIf="user.isAuthorized('OP_APR_WRITE')"
              [routerLink]="['/apr/materials']"
              appCloseMenuOnClick
              aria-expanded="true"
            ><i class="far fa-wrench"></i> Matériels</a
            >
          </li>
          <li>
            <a
              *ngIf="user.isAuthorized('OP_APR_WRITE')"
              [routerLink]="['/apr/containers']"
              appCloseMenuOnClick
              aria-expanded="true"
              ><i class="far fa-map-marker"></i> Emplacements</a
            >
          </li>
        </ul>
      </li>

      <li
        [style.display]="user.isAuthorized('OP_USERS_MANAGEMENT') || user.isAuthorized('OP_ANNOUNCE_ADMIN') ? '':'none'"
      >
        <a
          aria-expanded="false"
          class="has-arrow"
          role="button"
          title="Administration"
        >
          <i class="fas fa-tools fa-fw"></i>
          <span class="nav-label"> Administration</span>
        </a>
        <ul class="nav nav-second-level">
          <li *ngIf="user.isAuthorized('OP_ANNOUNCE_ADMIN')">
            <a [routerLink]="['/annonce/admin']" appCloseMenuOnClick
            ><i class="far fa-envelope"></i> Gérer les annonces</a
            >
          </li>
          <li *ngIf="user.isAuthorized('OP_USERS_MANAGEMENT')" title="Utilisateurs, tags et droits">
            <a aria-expanded="false" class="has-arrow" role="button">
              <i class="fas fa-users"></i> Utilisateurs
            </a>
            <ul class="nav nav-third-level">
              <li>
                <a [routerLink]="['/users']" appCloseMenuOnClick
                ><i class="far fa-users"></i> Utilisateurs</a
                >
              </li>
              <li *ngIf="user.isAuthorized('OP_ANNOUNCE_ADMIN')">
                <a [routerLink]="['/tags']" appCloseMenuOnClick>
                  <em class="fal fa-user-tag"></em> Tags
                </a>
              </li>
              <li>
                <a [routerLink]="['/permissions']" appCloseMenuOnClick
                ><i class="fas fa-shield-alt"></i> Rôles et droits</a
                >
              </li>
            </ul>
          </li>

          <li
            *ngIf="version?.isAuthorized('CONVOCATION') && user.isAuthorized('OP_CONVOCATION_ADMIN')"
          >
            <a [routerLink]="['/convocations/gerer']" appCloseMenuOnClick>
              <i class="far fa-calendar-plus"></i> Gérer les convocations
            </a>
          </li>
          <li
            *ngIf="version?.isAuthorized('FORMULAIRES') && user.isAuthorized('OP_FORM_ADMIN')"
          >
            <a [routerLink]="['/formulaires/rapports']" appCloseMenuOnClick>
              <i class="far fa-list"></i> Gestion des heures non soldées
            </a>
          </li>

          <li>
            <a
              *ngIf="user.isRoot()"
              [routerLink]="['/sqrs-dashboard']"
              appCloseMenuOnClick
            ><i class="far fa-debug"></i> Sqrs dashboard</a
            >
          </li>
        </ul>
      </li>

      <li *ngIf="user.isAuthorized('ROLE_SAPEUR') && version?.isAuthorized('MIRDOCS')">
        <a appCloseMenuOnClick [routerLink]="['/mirdocs']">
          <i class="fas fa-disc-drive fa-fw"></i>
          <span class="nav-label"> Mirdocs</span>
        </a>
      </li>

      <li>
        <a aria-expanded="false" class="has-arrow" role="button" title="Liens">
          <i class="far fa-link fa-fw"></i>
          <span class="nav-label"> Liens</span>
        </a>
        <ul class="nav nav-second-level">
          <li>
            <a
              appCloseMenuOnClick
              href="{{version.sdis.publicWebSite}}"
              target="_blank"
              title="{{version.sdis.name}}"
            >
              <i class="fas fa-external-link-square-alt fa-fw"></i>
              {{version.sdis.name}}
            </a>
          </li>
          <li *ngIf="version.sdis.ecaPortal">
            <a
              appCloseMenuOnClick
              href="{{version.sdis.ecaPortal}}"
              target="_blank"
              title="Portail ECA"
            >
              <i class="fas fa-external-link-square-alt fa-fw"></i>
              Portail ECA
            </a>
          </li>
          <li>
            <a
              appCloseMenuOnClick
              href="https://storage.118-vaud.ch/login"
              target="_blank"
              title="ECABOX"
            >
              <i class="fas fa-external-link-square-alt fa-fw"></i>
              ECABOX
            </a>
          </li>
          <ng-container *ngIf="version.sdis.customLinks">
            <li *ngFor="let link of version.sdis.customLinks">
              <a
                appCloseMenuOnClick
                href="{{link.href}}"
                target="_blank"
                [title]="link.title"
              >
                <i class="fas fa-external-link-square-alt fa-fw"></i>
                {{link.title}}
              </a>
            </li>
          </ng-container>
        </ul>
      </li>

      <li>
        <a appCloseMenuOnClick [routerLink]="['/about']">
          <i class="far fa-satellite"></i>
          <span class="nav-label"> Mir</span>
        </a>
      </li>

      <li class="d-xs-block d-sm-none">
        <a (click)="logout()" appCloseMenuOnClick href title="Déconnexion">
          <i class="far fa-sign-out"></i>
          <span class="nav-label"> Déconnexion</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
