import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GoogleData } from './google-data';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GoogleCalendarService {
  constructor(private http: HttpClient) {
  }

  getPiquets() {
    return this.http
      .get('/api/googlecalendar/today')
      .pipe(map((data: any) => new GoogleData(data)));
  }

  getFollowing() {
    return this.http
      .get('/api/googlecalendar/following')
      .pipe(map((data: any) => new GoogleData(data)));
  }
}
