import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-last-webeasy',
    templateUrl: './last-webeasy.component.html',
    standalone: false
})
export class LastWebeasyComponent implements OnInit {
  loading = true;
  reports: any[];

  constructor(private http: HttpClient) {}

  ngOnInit() {
    return this.http
      .get('/api/ecawin/statistics/inters')
      .subscribe((data: any[]) => {
        this.reports = data.slice(0, 9); // only the last 10
        this.loading = false;
      });
  }
}
