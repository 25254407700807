import {Component, Input, OnInit} from '@angular/core';
import {BookingService} from '../../services/booking.service';

@Component({
    selector: 'app-today-ecawin',
    templateUrl: './today-ecawin.component.html',
    styleUrls: ['./today-ecawin.component.scss'],
    standalone: false
})
export class TodayEcawinComponent implements OnInit {
  @Input()
  lite: boolean = false;

  events: any[];
  loading = true;

  constructor(private bookingService: BookingService) {}

  ngOnInit() {
    this.bookingService.getTodayEcawin().subscribe((data: any) => {
      this.events = data;
      this.loading = false;
    });
  }
}
