import {Component, OnInit} from '@angular/core';
import {PermissionsService} from '../permissions.service';
import {Auth2Service} from '../../../auth/auth2.service';
import {User} from '../../../model/user';

@Component({
    selector: 'app-role-operation',
    templateUrl: './role-operation.component.html',
    standalone: false
})
export class RoleOperationComponent implements OnInit {
  roles: any[];

  user: User;

  constructor(private authService: Auth2Service, private permissionsService: PermissionsService) {
  }

  ngOnInit() {
    this.user = this.authService.loggedUser;
    this.permissionsService
      .findAllRoles()
      .subscribe((data: any[]) => (this.roles = data));
  }
}
