export class GoogleData {
  calendars: GoogleCalendar[] = [];

  constructor(json?: any) {
    if (json) {
      this.calendars = json?.calendars.map(v => new GoogleCalendar(v));
    }
  }
}

export class GoogleCalendar {
  name: string;
  events: GoogleEvent[] = [];

  constructor(json?: any) {
    if (json) {
      this.name = json.name;
      this.events = json?.events.map(v => new GoogleEvent(v));
    }
  }
}

export class GoogleEvent {
  text: string;
  startDate: Date;
  endDate: Date;

  constructor(json?: any) {
    if (json) {
      this.text = json.text;
      this.startDate = new Date(json.startDate);
      this.endDate = new Date(json.endDate);
    }
  }
}
