<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>
      Rôle
    </h2>
  </div>
</div>

<div class="row m-t-sm">

  <div class="col-sm-9">
    <table class="table table-sm">
      <thead>
      <tr>
        <th>Role</th>
        <th *ngIf="user.isRoot()">Operation</th>
        <th>Utilisateurs</th>
      </tr>
      </thead>
      <tr *ngFor="let role of roles">
        <td>{{ role.name }}</td>
        <td *ngIf="user.isRoot()">
          <dl class="list-group">
            <dd class="list-group-item" *ngFor="let op of role.operations">
              <span>{{ op.name }}</span>
            </dd>
          </dl>
        </td>
        <td>
          <dl>
            <dd *ngFor="let u of role.users">
              <a [routerLink]="[u.id]">{{ u.fullname }}</a>
            </dd>
          </dl>
        </td>
      </tr>
    </table>
  </div>
</div>
