import {Component, OnDestroy, OnInit} from '@angular/core';
import {MsgrService} from '../../services/msgr.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-last-msgr',
    templateUrl: './last-msgr.component.html',
    standalone: false
})
export class LastMsgrComponent implements OnInit, OnDestroy {
  alarms: any[] = [];
  loading = true;

  subscription: Subscription;

  constructor(private msgrService: MsgrService) {}

  ngOnInit() {
    this.subscription = this.msgrService
      .listenForChange()
      .subscribe((alarms: any[]) => {
        if (alarms && alarms.length > 5) {
          this.alarms = alarms.slice(0, 5);
        } else {
          this.alarms = alarms;
        }
        this.loading = false;
      });
    this.msgrService.load();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
