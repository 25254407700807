import {AfterViewInit, Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../model/user';
import {Auth2Service} from '../../auth/auth2.service';
import {Version} from '../../model/version';

declare var jQuery: any;

@Component({
    selector: 'navigation',
    templateUrl: 'navigation.template.html',
    standalone: false
})
export class NavigationComponent implements AfterViewInit {
  @Input()
  user: User;

  @Input()
  version: Version;

  constructor(private router: Router, private auth2Service: Auth2Service) {}

  getLogo() {
    return 'assets/images/' + this.version.sdis.code + '/logo.png';
  }

  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();
  }

  logout() {
    this.auth2Service.logout();
    this.router.navigateByUrl('/login');
  }
}
