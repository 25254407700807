import {Component, Input} from '@angular/core';
import {Version} from '../../model/version';
import {User} from '../../model/user';

@Component({
    selector: 'footer',
    templateUrl: 'footer.template.html',
    standalone: false
})
export class FooterComponent {
  @Input()
  version: Version;

  @Input()
  user: User;

  moment: Date = new Date();
}
