import {Routes} from '@angular/router';

import {BasicLayoutComponent} from './layout/layouts/basicLayout.component';
import {AuthGuard} from './auth/auth-guard.service';
import {UserlistComponent} from './admin/users/userlist.component';
import {RoleOperationComponent} from './admin/permissions/role-operation/role-operation.component';
import {AddUserToRoleComponent} from './admin/permissions/add-user-to-role/add-user-to-role.component';
import {DashboardComponent} from './dashboards/dashboard.component';
import {MirAboutComponent} from './mir-about/mir-about.component';
import {TagsViewComponent} from './admin/tags/tags-view/tags-view.component';
import {VersionResolve} from './version.resolve.service';
import {SqrsDashboardComponent} from './admin/sqrs-dashboard/sqrs-dashboard.component';
import {SigninComponent} from './auth/signin/signin.component';

export const ROUTES: Routes = [
  // Main redirect
  {
    path: '',
    canActivate: [AuthGuard],
    component: BasicLayoutComponent,
    children: [
      { path: 'about', component: MirAboutComponent, resolve: { version: VersionResolve } },
      { path: 'sqrs-dashboard', component: SqrsDashboardComponent },
      { path: 'calendar', redirectTo: '/booking/calendar' },
      {
        path: 'msgr',
        loadChildren: () =>
          import('./plugin-msgr/msgr.module').then(m => m.MsgrModule)
      },
      {
        path: 'statistics',
        loadChildren: () =>
          import('./plugin-webeasy/webeasy.module').then(m => m.WebeasyModule)
      },
      {
        path: 'annonce',
        loadChildren: () =>
          import('./plugin-announce/plugin-announce.module').then(
            m => m.PluginAnnounceModule
          )
      },
      {
        path: 'booking',
        loadChildren: () =>
          import('./plugin-booking/booking.module').then(m => m.BookingModule)
      },
      {
        path: 'roulage',
        loadChildren: () =>
          import('./plugin-roulage/roulage.module').then(m => m.RoulageModule)
      },
      {
        path: 'apr',
        loadChildren: () =>
          import('./plugin-apr/apr.module').then(m => m.AprModule)
      },
      {
        path: 'convocations',
        loadChildren: () =>
          import('./plugin-convocations/convocations.module').then(
            m => m.ConvocationsModule
          )
      },
      {
        path: 'formulaires',
        loadChildren: () =>
          import('./plugin-formulaires/formulaires.module').then(
            m => m.FormulairesModule
          )
      },
      {
        path: 'failure',
        loadChildren: () =>
          import('./plugin-failure/plugin-failure.module').then(
            m => m.PluginFailureModule
          )
      },
      {
        path: 'annuaire',
        loadChildren: () =>
          import('./plugin-annuaire/plugin-annuaire.module').then(
            m => m.PluginAnnuaireModule
          )
      },
      {
        path: 'mirdocs',
        loadChildren: () =>
          import('./plugin-mirdocs/plugin-mirdocs.module').then(
            m => m.PluginMirdocsModule
          )
      },
      { path: 'users', component: UserlistComponent },
      { path: 'tags', component: TagsViewComponent },
      { path: 'tags/:userId', component: TagsViewComponent },
      { path: 'permissions', component: RoleOperationComponent },
      { path: 'permissions/:userId', component: AddUserToRoleComponent },
      { path: '', component: DashboardComponent, pathMatch: 'full' }
    ],
    resolve: {
      version: VersionResolve
    }
  },

  {
    path: '',
    children: [
      {
        path: 'login',
        component: SigninComponent,
        resolve: { version: VersionResolve },
      }
    ]
  },

  // Handle all other routes
  { path: '**', redirectTo: 'login' }
];
