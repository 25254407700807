<ng-container *ngIf="!user">
  <div class="m-r m-t">
    <button (click)="reload()" class="btn btn-default">
      <em class="far fa-3x fa-redo"></em>
      Cliquez pour recharger
    </button>
  </div>
</ng-container>
<ng-container *ngIf="user">

  <mir-sqrs-broadcast [login]="false"></mir-sqrs-broadcast>

  <mir-shortcuts-bar [user]="user" [version]="version"></mir-shortcuts-bar>

  <mir-desktop-dashboard class="d-none d-md-block" [user]="user" [version]="version"></mir-desktop-dashboard>
</ng-container>
