<div class="row justify-content-md-center bg-white">
  <div class="col-lg-2">
  </div>
  <div class="col-md-auto bg-white m-md">
    <div *ngIf="!cacheReload" class="middle-box text-center loginscreen bg-eca">
        <img [src]="getLogo()"  style="max-width: 300px"/> <br/>
        <br/>
      <div class="p-2">

      <mir-sqrs-broadcast [login]="true"></mir-sqrs-broadcast>

        <form class="m-t" role="form">
          <div class="form-group">
            <input
              [placeholder]="'NIP'"
              class="form-control"
              name="username"
              #username
              required
              placement="right"
              tooltip="Votre NIP ECA"
            />
          </div>
          <div class="form-group">
            <input
              [placeholder]="'Mot de passe'"
              class="form-control"
              name="password"
              #password
              ngModel
              required
              placement="right"
              tooltip="Mot de passe ECA"
              type="password"
            />
          </div>

          <button
            *ngIf="!loading"
            (click)="onSignin(username.value, password.value)"
            class="btn btn-primary block full-width m-b"
            type="submit"
          >
            Connexion
          </button>

          <div *ngIf="loading" class="mb-2 text-primary">
            <em class="fal fa-3x fa-spin fa-spinner"></em>
          </div>

        </form>

      <div class="panel panel-warning mt-2" *ngIf="pleaseCaptcha">
        <div class="panel-heading"><em class="fal fa-exclamation-triangle"></em> Captcha !</div>
        <div class="panel-body">Merci de valider le captcha.</div>
      </div>
      <div style="transform:scale(0.88);transform-origin:0 0;">
        <re-captcha
          #captchaElement
          (resolved)="resolved($event)"
          siteKey="6LfYJzEbAAAAAEAS1Rd-1rmBVCMtYDcTVCAI4JCO"
        ></re-captcha>
      </div>


      <a class="mt-lg-4 text-white" href="https://mypassword.118-vaud.ch/public/forgottenpassword">Mot de passe perdu ?</a>

    </div>
    </div>


    <div class="middle-box text-center loginscreen" *ngIf="cacheReload">
      <h2 class="text-navy">Mir est en cours de rechargement ...</h2>
    </div>
  </div>
  <div class="col-lg-2">

  </div>


</div>



