<div class="row border-bottom">
  <nav
    class="navbar navbar-static-top white-bg"
    role="navigation"
    style="margin-bottom: 0"
  >
    <div class="navbar-header">
      <a
        class="minimalize-styl-2 btn "
        [ngClass]="notProd() ? 'btn-warning' : 'btn-primary' "
        (click)="toggleNavigation()"
      ><i
        class="far"
        [ngClass]="notProd() ? 'fa-exclamation-triangle' : 'fa-bars'"
      ></i>
      </a>
      <ng-container *ngIf="notProd()">
        <span class="minimalize-styl-2 alert alert-warning text-uppercase">
          <strong>Mir hors production 3.8</strong>
        </span>
      </ng-container>
    </div>
    <ul class="nav navbar-top-links navbar-right">
      <li class="text-white">
        <span class="d-none d-md-block">{{user?.rank}} {{user?.fullname}}</span>
      </li>
      <li *ngIf="user?.realLoggedUsername" class="small m-l">
        <button
          class="btn btn-danger btn-sm d-none d-md-block"
          (click)="impersonateLogout()"
        >
          <i class="far fa-user-secret"></i>
        </button>
      </li>
      <li *ngIf="version?.isAuthorized('ANNOUNCE') && user?.isAuthorized('ROLE_SAPEUR')" class="dropdown" dropdown>
        <a dropdownToggle class="count-info d-none d-md-block">
          <em
            class="fas"
            [ngClass]="announces?.myNotificationsUnread.length > 0 ? 'fa-bell-on' : 'fa-bell'"
          ></em>
          <span
            *ngIf="announces?.myNotificationsUnread.length > 0"
            class="label label-primary"
          >
            {{announces.myNotificationsUnread.length}}</span
          >
        </a>
        <ul
          *dropdownMenu
          class="dropdown-menu dropdown-alerts dropdown-menu-right"
        >
          <li
            role="menuitem"
            *ngIf="announces.myNotificationsUnread.length > 0"
          >
            <a [routerLink]="['/annonce']" class="dropdown-item">
              <div>
                <i class="fal fa-inbox-in fa-fw fa-2x"></i> Vous avez
                <b>{{ announces.myNotificationsUnread.length}}</b
                ><span *ngIf="announces.myNotificationsUnread.length > 1">
                  annonces non lues</span
              ><span *ngIf="announces.myNotificationsUnread.length <= 1">
                  annonce non lue</span
              >
              </div>
            </a>
          </li>
          <li
            class="divider dropdown-divider"
            *ngIf="announces.myNotificationsUnread.length > 0"
          ></li>
          <li>
            <div class="text-center link-block">
              <a [routerLink]="['/annonce']" class="dropdown-item">
                <strong
                >Consulter les {{announces.all.length}} annonces
                  actives</strong
                >
              </a>
            </div>
          </li>
        </ul>
      </li>
      <li>
        <a (click)="logout()" class="d-none d-md-block">
          <i class="far fa-sign-out"></i> Déconnexion
        </a>
      </li>
    </ul>
  </nav>
</div>
