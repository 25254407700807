<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>
      Liste des utilisateurs
      <span *ngIf="archives">archivés / bloqués</span> ({{ totalItems }})
    </h2>
  </div>
</div>

<div *ngIf="duplicates?.length > 0" class="row m-t-sm">
  <div class="col-lg-12">
    <div class="alert alert-warning" role="alert">
      <h4 class="alert-heading">NIP dupliqué !</h4>
      <ul>
        <li *ngFor="let u of duplicates">{{u.fullname}} <span *ngIf="u.blocked">(Archivé)</span> - Nip: {{u.nip}}</li>
      </ul>
      <hr>
      <span>Veuillez modifier le NIP</span>
    </div>
  </div>
</div>

<div class="row m-t-sm">
  <div class="col-lg-12">
    <div class="row">
      <div class="col-lg-6">
        <pagination
          class="d-none d-md-block"
          (numPages)="smallnumPages = $event"
          (pageChanged)="pageChanged($event)"
          [(ngModel)]="currentPage"
          [directionLinks]="true"
          [itemsPerPage]="30"
          [totalItems]="totalItems"
          nextText="Suivant"
          previousText="Précédent"
        >
        </pagination>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <div class="input-group">
            <input
              #search
              (keyup.enter)="filterByLastname(search.value)"
              class="form-control"
              placeholder="Nom de famille, courriel, nip"
              type="text"
            />
            <span class="input-group-btn">
              <button
                (click)="filterByLastname(search.value)"
                class="btn btn-primary btn-lg"
              >
                <i class="far fa-filter"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="d-none d-md-block">
          <button
            tooltip="Ajouter un utilisateur"
            (cancel)="nothing()"
            (confirm)="createUser($event)"
            [swal]="{
              title: 'Ajouter un utilisateur',
              icon: 'info',
              text: 'NIP de la personne',
              input: 'text',
              showCancelButton: true,
              cancelButtonText: 'Annuler',
              confirmButtonText: 'Ajouter',
              animation: false
            }"
            class="btn btn-primary btn-lg"
          >
            <i class="fal fa-plus"></i>
          </button>
          &nbsp;
          <button
            tooltip="Voir les archives"
            (click)="toggleArchives()"
            [ngClass]="!archives ? 'btn-primary' : 'btn-warning'"
            class="btn btn-lg"
          >
            <em class="far fa-archive"></em>
          </button>
          &nbsp;
          <button
            *ngIf="loggedUser.isRoot()"
            tooltip="Import des utilisateurs"
            (cancel)="nothing()"
            (confirm)="importEcawin()"
            [swal]="{
              icon: 'warning',
              title: 'Mise à jour des utilisateurs.',
              text: 'Cette action ne peut pas être annulée.',
              showCancelButton: true,
              cancelButtonText: 'Annuler',
              confirmButtonText: 'Oui, je veux importer'
            }"
            class="btn btn-warning btn-lg"
          >
            <em class="far fa-file-import"></em>
          </button>
        </div>
      </div>
    </div>

    <table class="table table-hover table-responsive">
      <thead>
      <tr>
        <th (click)="sortBy('lastname')">
          Nom
          <ng-container *ngIf="sortColumn == 'lastname'">
              <span *ngIf="sortDirection == 'ASC'">
                <i class="far fa-sort-up"></i>
              </span>
            <span *ngIf="sortDirection == 'DESC'">
                <i class="far fa-sort-down"></i>
              </span>
          </ng-container>
        </th>
        <th (click)="sortBy('firstname')">
          Prénom
          <ng-container *ngIf="sortColumn == 'firstname'">
              <span *ngIf="sortDirection == 'ASC'">
                <i class="far fa-sort-up"></i>
              </span>
            <span *ngIf="sortDirection == 'DESC'">
                <i class="far fa-sort-down"></i>
              </span>
          </ng-container>
        </th>
        <th (click)="sortBy('rank')">
          Grade
          <ng-container *ngIf="sortColumn == 'rank'">
              <span *ngIf="sortDirection == 'ASC'">
                <i class="far fa-sort-up"></i>
              </span>
            <span *ngIf="sortDirection == 'DESC'">
                <i class="far fa-sort-down"></i>
              </span>
          </ng-container>
        </th>
        <th>Courriel</th>
        <th (click)="sortBy('site.name')">
          OI
          <ng-container *ngIf="sortColumn == 'site.name'">
              <span *ngIf="sortDirection == 'ASC'">
                <i class="far fa-sort-up"></i>
              </span>
            <span *ngIf="sortDirection == 'DESC'">
                <i class="far fa-sort-down"></i>
              </span>
          </ng-container>
        </th>
        <th>Nip</th>
        <th>Num. Perso.</th>
        <th (click)="sortBy('lastConnection')">
          Dernière connexion
          <ng-container *ngIf="sortColumn == 'lastConnection'">
              <span *ngIf="sortDirection == 'ASC'">
                <i class="far fa-sort-up"></i>
              </span>
            <span *ngIf="sortDirection == 'DESC'">
                <i class="far fa-sort-down"></i>
              </span>
          </ng-container>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr
        *ngFor="let user of users"
        [ngClass]="user.status === 'BLOCKED' ? 'warning' : ''"
      >
        <ng-container *ngIf="editRow == user">
          <td>
            <input
              [(ngModel)]="user.lastname"
              class="form-control"
              type="text"
            />
          </td>
          <td>
            <input
              [(ngModel)]="user.firstname"
              class="form-control"
              type="text"
            />
          </td>
          <td>
            <input [(ngModel)]="user.rank" class="form-control" type="text"/>
          </td>
          <td>
            <input
              [(ngModel)]="user.username"
              class="form-control"
              type="text"
            />
          </td>

          <td>
            <select [(ngModel)]="user.site" class="form-control" [compareWith]="compareSitesById">
              <ng-container *ngFor="let site of sites">
                <option  [ngValue]="site">
                  {{site.name}}</option>
              </ng-container>

            </select>
          </td>
          <td>
            <input [(ngModel)]="user.nip" class="form-control" type="text"/>
          </td>
          <td>
            <input
              [(ngModel)]="user.personalNumber"
              class="form-control"
              type="text"
            />
          </td>
          <td class="d-none d-md-block">{{ user.lastConnection }}</td>
          <td>
            <button (click)="save(user)" class="btn btn-primary">
              <i class="far fa-save"></i>
            </button>
            <button (click)="cancel()" class="btn btn-default">
              <i class="far fa-ban"></i>
            </button>
          </td>
        </ng-container>
        <ng-container *ngIf="editRow != user">
          <td>{{ user.lastname }}</td>
          <td>{{ user.firstname }}</td>
          <td>{{ user.rank }}</td>
          <!--'2017,5,15,20,42,57,457000000'-->
          <td class="d-none d-md-block">{{ user.username }}</td>
          <td>{{ user.site?.name }}</td>
          <td>{{ user.nip }}</td>
          <td>{{ user.personalNumber }}</td>
          <td>
            <ng-container class="d-none d-md-block">

            {{ user.lastConnection | date: 'd MMM yyyy' }}
            <span *ngIf="user.lastConnection"
            >({{ user.lastConnection | amLocale: 'fr' | amTimeAgo }})</span
            >
            </ng-container>

          </td>
          <td>
            <button
              tooltip="Changer le mot de passe"
              (confirm)="updatePassword(user, $event)"
              (cancel)="nothing()"
              [swal]="{showCancelButton: true, icon: 'info', input: 'password', text: 'Le mot de passe ne sera pas synchronisé avec l\'ECA', title: 'Forcer un mot de passe local'}"
              *ngIf="loggedUser.isAuthorized('OP_USERS_MANAGEMENT')"
              class="m-xxs btn btn-default"
            >
              <em class="fal fa-key"></em>
            </button>
            <button
              tooltip="Activer/désactiver LDAP"
              [ngClass]="user.ldap ? 'btn-default' : 'btn-danger'"
              (click)="togglLdap(user)"
              *ngIf="loggedUser.isAuthorized('OP_USERS_MANAGEMENT')"
              class="m-xxs btn"
            >
              Connexion ECA <em *ngIf="!user.ldap" class="fal fa-ban"></em>
            </button>
            <button
              tooltip="Voir les permissions de cet utilisateur"
              *ngIf="loggedUser.isAuthorized('OP_USERS_MANAGEMENT')"
              [routerLink]="['../permissions', user.id]"
              class="m-xxs btn btn-default"
            >
              Rôles
            </button>
            <button
              tooltip="Editer cet utilisateur"
              (click)="edit(user)"
              [disabled]="user.isAuthorized('ROLE_ROOT')"
              class="m-xxs btn btn-default"
            >
              <i class="fas fa-edit"></i>
            </button>
            <button
              tooltip="Se connecter en tant que cet utilisateur"
              (click)="impersonate(user)"
              *ngIf="loggedUser.isAuthorized('OP_IMPERSONATE')"
              [disabled]="user.isAuthorized('ROLE_ROOT')"
              class="m-xxs btn btn-default"
            >
              <i class="far fa-eye"></i>
            </button>

            <button
              tooltip="Voir les tags pour cet utilisateur"
              [routerLink]="['/tags', user.id]"
              *ngIf="loggedUser.isAuthorized('OP_MESSAGES_ADMIN')"
              class="m-xxs btn btn-default"
            >
              <i class="far fa-tag"></i>
            </button>

            <ng-container *ngIf="!user.blocked">
              <button
                tooltip="Bloquer cet utilisateur"
                (click)="lock(user)"
                [disabled]="user.isAuthorized('ROLE_ROOT')"
                class="m-xxs btn btn-default"
              >
                <i class="far fa-unlock"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="user.blocked">
              <button
                tooltip="Débloquer cet utilisateur"
                (click)="unlock(user)"
                [disabled]="user.isAuthorized('ROLE_ROOT')"
                class="m-xxs btn btn-warning"
              >
                <i class="far fa-lock"></i>
              </button>
            </ng-container>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>
</div>
