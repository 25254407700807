import {Component, OnInit} from '@angular/core';
import {Auth2Service} from './auth/auth2.service';
import {Angulartics2GoogleAnalytics} from "angulartics2";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent implements OnInit {
  constructor(
    private auth2Service: Auth2Service,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {
    this.angulartics2GoogleAnalytics.startTracking();

  }

  ngOnInit(): void {
    this.auth2Service.loadUser();
  }
}
