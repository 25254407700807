import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Auth2Service } from './auth/auth2.service';
import { ToastrService } from 'ngx-toastr';

/**
 * Created by dgo on 25.04.17.
 */
@Injectable()
export class MirHttpErrorHandler implements ErrorHandler {
  router: Router;

  constructor(private injector: Injector, private ngZone: NgZone) {}

  handleError(error) {
    if ((error && error.status == 403) || error.status == 401) {
      if (!this.injector.get(Auth2Service).isTokenExpired()) {
        this.showError(
          'Accès non autorisé',
          "Veuillez vérifier votre mot de passe ou si vous avez le droit d'accèder à cette page. (" +
            error.statusText +
            ')'
        );
      } else {
        //this.showError('Accès non autorisé', "Votre compte est actuellement bloqué");
      }
      this.injector.get(Auth2Service).logout();

      if (!this.router) {
        this.router = this.injector.get(Router);
      }
      this.router.navigate(['/signin']);

      return;
    }
    console.error(error);
    // this.injector.get(Auth2Service).logout(); // force cleanup token

    if (error && error.error) {
      // let data = JSON.parse(error.error);
      this.showError(
        'Cette opération a produit une erreur.',
        error.error.exception
      );
    } else if (error) {
      this.showError(
        'Oups !',
        'Veuillez contacter le support, si cette erreur réapparait. ' +
          error.status +
          ' (' +
          error.statusText +
          ')'
      );
    }

    return error;
  }

  // https://stackoverflow.com/questions/44975477/angular2-ng-toasty-errorhandler
  private showError(title: string, body: string) {
    this.ngZone.run(() => {
      this._toastrService.error(title, body);
    });
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  // https://github.com/scttcper/ngx-toastr/issues/804#issuecomment-619987359
  private get _toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }
}
