<ng-container *ngIf="myReservations.length > 0">
  <p>Vos prochaines commandes de matériel</p>

  <ul class="list-group">
    <li *ngFor="let res of myReservations" class="list-group-item">
      <small
      ><em class="fal fa-clock"></em>
        {{ res.start | date: 'EEEE d MMM' }}</small
      >
      <p>
        <a [routerLink]="['booking/view', res.id]">{{ res.eventName }}</a>
      </p>
    </li>
  </ul>
</ng-container>
