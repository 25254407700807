import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth2Service} from '../auth2.service';
import {Subscription} from 'rxjs';
import {VersionService} from '../../services/version.service';
import {Version} from '../../model/version';
import {RecaptchaComponent} from "ng-recaptcha-2";


@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    providers: [VersionService],
    styleUrls: ['./signin.component.css'],
    standalone: false
})
export class SigninComponent implements OnInit, OnDestroy {

  @ViewChild('captchaElement') captchaElement: RecaptchaComponent;

  loading: boolean; // spinner
  subscription: Subscription;

  version: Version;

  cacheReload = false;

  captcha: string;
  pleaseCaptcha: boolean = false;

  constructor(
    private auth2Service: Auth2Service,
    private activated: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.subscription = this.auth2Service
      .connected()
      .subscribe(theUserIsConnected => {
        if (theUserIsConnected) {
          this.router.navigateByUrl('/');
        }
      });

    this.activated.data?.subscribe(data => {
      this.version = new Version(data['version']);
    });
  }

  getLogo() {
    if (this.version && this.version.sdis) {
      return 'assets/images/' + this.version.sdis.code + '/logo.png';
    }
    return '';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onSignin(username: string, password: string): void {
    if (!this.captcha) {
      this.pleaseCaptcha = true;
      return;
    }
    if (username && password) {
      this.loading = true;
      this.auth2Service.login(username, password, this.captcha).subscribe(
        ok => {
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.captcha = null
          this.captchaElement.reset();
          throw error;
        }
      );
    }
  }

  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.captcha = captchaResponse;
      this.pleaseCaptcha = false;
    }
    else{
      console.log('expired')
      this.captcha = null;
      this.pleaseCaptcha = true;
    }
  }

  ended() {
    this.captcha = null;
    this.pleaseCaptcha = true;
  }


}
