<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>
      A propos de Mir
    </h2>
  </div>
</div>

<div class="row justify-content-center mt-3">
  <div class="col-sm-2 col-lg-9">
    <div class="jumbotron">
      <h1 class="display-4">MIR | <em class="fal fa-satellite fa-1x"></em></h1>
      <p class="lead">Mir est mis à disposition par ECA-Vaud et Square Services Sàrl</p>
      <hr class="my-4">
    </div>
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-lg-3">
    <div class="card m-3" style="width: 18rem;">
      <div class="card-header">
        <div class="text-center">
          <a href="https://www.squareservices.ch/">
            <img src="https://www.squareservices.ch/wp-content/uploads/2018/03/square-services-prestations-logo.svg"
                 width="200"/>
          </a>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a href="https://www.squareservices.ch" target="_blank">Square Services Sàrl
          </a></li>
          <li class="list-group-item">Ch. des Assenges 14</li>
          <li class="list-group-item">1142 Pampigny</li>

        </ul>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card m-3" style="width: 18rem;">
      <div class="text-center">
        <div class="card-header bg-eca">
          <a [href]="version?.sdis?.publicWebSite" target="_blank">
            <img [src]="getLogo()" width="200"/>
          </a>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">SDIS {{version?.sdis?.name}}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card m-3" style="width: 18rem;">
      <div class="text-center">
        <div class="card-header">
          <a href="https://www.eca-vaud.ch/">
            <img src="https://www.eca-vaud.ch/wp-content/uploads/2022/12/logo-eca.svg"/>
          </a>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><a href="https://www.eca-vaud.ch" target="_blank">ECA Vaud</a></li>
          <li class="list-group-item">Av. du Grey 111</li>
          <li class="list-group-item">1002 Lausanne</li>
        </ul>
      </div>
    </div>
  </div>
</div>


