import {Component, OnInit} from '@angular/core';
import {ConvocationService} from '../../../services/convocation.service';
import {Convocation} from '../../../model/convocation';

@Component({
    selector: 'app-my-convocations',
    templateUrl: './my-convocations.component.html',
    standalone: false
})
export class MyConvocationsComponent implements OnInit {
  myConvocations: Convocation[] = [];

  constructor(private convocationService: ConvocationService) {
  }

  ngOnInit() {
    this.convocationService
      .getMyConvocations(10)
      .subscribe((data: Convocation[]) => (this.myConvocations = data));
  }
}
