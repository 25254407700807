<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>
      Dashboard opérations techniques
    </h2>
  </div>
</div>

<div class="row m-t-sm">
  <div class="col-lg-12">
    <div class="card no-borders">
      <div class="card-body">
        <h2>Envoi d'un mail de test</h2>
        <button class="btn btn-primary" (click)="sendMail()">
          Envoi mail test
        </button>

        <div class="card-body" *ngIf="error">
          <h3>Erreur</h3>
          <pre>{{ error | json }}</pre>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="card no-borders">
      <div class="card-body">
        <h2>Vider les caches Ecawin</h2>
        <button class="btn btn-primary" (click)="clearEcawinCaches()">
          Vider
        </button>

        <div class="card-body" *ngIf="error">
          <h3>Erreur</h3>
          <pre>{{ error | json }}</pre>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="card no-borders">
      <div class="card-body">
        <h2>LDAP</h2>
        <button class="btn" [ngClass]="ldap ? 'btn-primary' : 'btn-danger'"  (click)="toggleLdap()">
          <span *ngIf="ldap"><em class="fal fa-check"></em> LDAP activé</span>
          <span *ngIf="!ldap"><em class="fal fa-ban"></em> LDAP désactivé</span>
        </button>

        <button class="btn btn-secondary ml-3"  (click)="testLdap()">
          Test LDAP
        </button>

        <div class="card-body" *ngIf="error">
          <h3>Erreur</h3>
          <pre>{{ error | json }}</pre>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="card no-borders">
      <div class="card-body">
        <h2>Recaptcha</h2>
        <button class="btn" [ngClass]="recaptcha ? 'btn-primary' : 'btn-danger'" (click)="toggleRecaptcha()">
          <span *ngIf="recaptcha"><em class="fal fa-check"></em> Recaptcha activé</span>
          <span *ngIf="!recaptcha"><em class="fal fa-ban"></em> Recaptcha désactivé</span>
        </button>

        <div class="card-body" *ngIf="error">
          <h3>Erreur</h3>
          <pre>{{ error | json }}</pre>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="card no-borders">
      <div class="card-body">
        <h2>Messages broadcast</h2>

        <div class="card no-borders">
          <mir-sqrs-broadcast></mir-sqrs-broadcast>
        </div>

        <table class="table table-responsive table-hover">
          <tr>
            <th>SDIS</th>
            <th>Login Only</th>
            <th>Sujet</th>
            <th>Message</th>
            <th>Date</th>
            <th>Fichier</th>
            <th>Criticité</th>
            <th>Icône</th>
            <td class="text-right">Actions</td>
          </tr>
          <tr *ngFor="let msg of messages">
            <td>{{ msg.sdis }}</td>
            <td>{{ msg.loginOnly }}</td>
            <td>{{ msg.subject }}</td>
            <td>{{ msg.body }}</td>
            <td>{{ msg.dateFrom }}</td>
            <td>{{ msg.filename }}</td>
            <td>{{ msg.criticity }}</td>
            <td>
              <badge class="badge" [ngClass]="'badge-' + msg.criticity">
                <em class="fal fa-2x" [ngClass]="msg.icon"></em
                ></badge>
            </td>
            <td class="text-right">
              <button class="btn btn-default ml-1" (click)="showEditModal(msg)">
                <em class="fal fa-pencil"></em>
              </button>
              <button
                class="btn ml-1"
                [ngClass]="
                  msg.filename.endsWith('.disabled')
                    ? 'btn-danger'
                    : 'btn-primary'
                "
                (click)="toggleMessage(msg)"
              >
                <em
                  class="far"
                  [ngClass]="
                    msg.filename.endsWith('.disabled')
                      ? 'fa-toggle-off'
                      : 'fa-toggle-on'
                  "
                ></em>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
