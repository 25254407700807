import {Component, OnInit} from '@angular/core';
import {Version} from '../model/version';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-mir-about',
    templateUrl: './mir-about.component.html',
    standalone: false
})
export class MirAboutComponent implements OnInit {

  version: Version;

  constructor(private route: ActivatedRoute) {}

  getLogo() {
    return 'assets/images/' + this.version?.sdis?.code + '/logo.png';
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.version = new Version(data['version']);
    });
  }
}
