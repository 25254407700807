<div class="row">
  <div class="col-lg-6">
    <button
      class="btn btn-primary float-right"
      [swal]="{
        title: 'Créer un tag',
        icon: 'info',
        text: 'Créer un nouveeau tag',
        showCancelButton: true,
        input: 'text',
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Créer'
      }"
      (confirm)="createTag($event)"
    >
      <em class="fal fa-plus"></em> Nouveau tag
    </button>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <table class="table table-hover table-responsive" *ngIf="tags">
      <thead>
      <tr>
        <th>Tag</th>
        <th class="text-right">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let tag of tags">
        <td>
          <a (click)="showPeopleForTag(tag)">{{ tag.name }}</a>
        </td>
        <td>
          <button
            class="btn btn-warning m-r"
            [swal]="{
                title: 'Renommer le tag',
                icon: 'question',
                input: 'text',
                text: 'Renommer ' + tag.name,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Renommer'
              }"
              (confirm)="renameTag(tag, $event)"
            >
              <em class="fal fa-pencil"></em>
            </button>
            <button
              class="btn btn-danger"
              [swal]="{
                title: 'Supprimer le tag',
                icon: 'error',
                text: 'Supprimer ' + tag.name,
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Supprimer'
              }"
              (confirm)="removeTag(tag)"
            >
              <em class="fal fa-trash"></em>
            </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
