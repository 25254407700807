import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import {BasicLayoutComponent} from './basicLayout.component';

import {NavigationComponent} from '../navigation/navigation.component';
import {FooterComponent} from '../footer/footer.component';
import {TopNavbarComponent} from '../topnavbar/topnavbar.component';
import {MomentModule} from 'ngx-moment';
import {CloseMenuOnClickDirective} from '../navigation/close-menu-on-click.directive';
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    FooterComponent,
    BasicLayoutComponent,
    NavigationComponent,
    TopNavbarComponent,
    CloseMenuOnClickDirective
  ],
  imports: [
    MomentModule,
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot()
  ],
  exports: [
    FooterComponent,
    BasicLayoutComponent,
    NavigationComponent,
    TopNavbarComponent
  ]
})
export class LayoutsModule {}
