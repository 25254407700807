import {NgModule} from '@angular/core';

import {DashboardComponent} from './dashboard.component';
import {FormsModule} from '@angular/forms';
import {UsersService} from '../services/users.service';
import {RouterModule} from '@angular/router';
import {LastMsgrComponent} from './last-msgr/last-msgr.component';
import {LastWebeasyComponent} from './last-webeasy/last-webeasy.component';
import {MyBookingsComponent} from './my-bookings/my-bookings.component';
import {MomentModule} from 'ngx-moment';
import {TodayEcawinComponent} from './today-ecawin/today-ecawin.component';
import {MyConvocationsComponent} from './my-convocations/my-convocations/my-convocations.component';
import {PluginGoogleCalendarModule} from '../plugin-google-calendar/plugin-google-calendar.module';
import {DesktopDashboardComponent} from './desktop-dashboard/desktop-dashboard.component';
import {ShortcutsBarComponent} from './shortcuts-bar/shortcuts-bar.component';
import {SqrsBroadcastComponent} from './sqrs-broadcast/sqrs-broadcast.component';
import {AnnouncesWidgetComponent} from './announces-widget/announces-widget.component';
import {SharedModule} from '../shared/shared.module';
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    DashboardComponent,
    LastMsgrComponent,
    LastWebeasyComponent,
    MyBookingsComponent,
    TodayEcawinComponent,
    MyConvocationsComponent,
    DesktopDashboardComponent,
    ShortcutsBarComponent,
    SqrsBroadcastComponent,
    AnnouncesWidgetComponent
  ],
    imports: [
        CommonModule,
        MomentModule,
        FormsModule,
        RouterModule,
        PluginGoogleCalendarModule,
      SharedModule
    ],
  exports: [DashboardComponent, SqrsBroadcastComponent],
  providers: [UsersService]
})
export class DashboardsModule {}
