<div class="ibox float-e-margins">
  <div class="ibox-content no-borders">
    <div>
      <h4>Alarmes reçues</h4>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let a of alarms">
          <small>
            <em class="fal fa-clock"></em>&nbsp;{{ a.time | amTimeAgo }}
          </small>
          <p style="word-break: break-all">{{ a.message }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="sk-loading" *ngIf="loading">
  <div class="spiner-example">
    <div class="sk-spinner sk-spinner-wave">
      <div class="sk-rect1"></div>
      <div class="sk-rect2"></div>
      <div class="sk-rect3"></div>
      <div class="sk-rect4"></div>
      <div class="sk-rect5"></div>
    </div>
  </div>
</div>
