import {Component, OnInit} from '@angular/core';
import {PermissionsService} from '../permissions.service';
import {ActivatedRoute} from '@angular/router';
import {AdminUserService} from '../../../services/admin-user.service';
import {User} from '../../../model/user';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-add-user-to-role',
    templateUrl: './add-user-to-role.component.html',
    standalone: false
})
export class AddUserToRoleComponent implements OnInit {
  user: User;
  newRole: number;

  allRoles: any[];

  constructor(
    private permissionsService: PermissionsService,
    private activatedRoute: ActivatedRoute,
    private adminUserService: AdminUserService,
    private toasterService: ToastrService
  ) {}

  ngOnInit() {
    let userId = this.activatedRoute.snapshot.params['userId'];
    this.adminUserService
      .getUserDetails(userId)
      .subscribe(data => (this.user = new User(data)));
    this.permissionsService
      .findAllRoles()
      .subscribe((data: any[]) => (this.allRoles = data));
  }

  availableRoles() {
    return this.allRoles.filter(
      item => this.user.permissions.indexOf(item.name) < 0
    );
  }

  addRole() {
    return this.permissionsService
      .assign({ userId: this.user.id, roleId: this.newRole })
      .subscribe(data => {
        this.user = new User(data);
        this.toasterService.success('Rôle ajouté');
      });
  }

  unassign(roleName: String) {
    return this.permissionsService
      .unassign({ userId: this.user.id, roleName: roleName })
      .subscribe(data => {
        this.user = new User(data);
        this.toasterService.success('Rôle supprimé');
      });
  }
}
