<div *ngIf="messages?.length > 0" class="panel panel-warning">
  <ng-container *ngFor="let msg of messages">
    <div class="panel-heading">
      <em class="fal fa-fw fa-2x m-1" [ngClass]="msg.icon" *ngIf="msg.icon"></em
      ><span class="font-bold text-lg-center">{{ msg.subject }}</span>
    </div>
    <div [ngClass]="'panel-' + msg.criticity" class="panel-body" >
      <span [innerHTML]="msg.body"></span>
      <br>
        <span *ngIf="msg.dateFrom" class="float-right text-muted small"
        >Mise à jour : {{ msg.dateFrom }}</span
        >
    </div>
  </ng-container>
</div>
