import {Component, OnInit} from '@angular/core';
import {BookingService} from '../../services/booking.service';
import {Reservation} from '../../plugin-booking/model/reservation';

@Component({
    selector: 'app-my-bookings',
    templateUrl: 'my-bookings.component.html',
    standalone: false
})
export class MyBookingsComponent implements OnInit {
  myReservations: Reservation[] = [];

  constructor(private bookingService: BookingService) {}

  ngOnInit() {
    this.bookingService
      .getReservationsPage(true, 0, 100, 'startTime', 'ASC')
      .subscribe((data: any) => (this.myReservations = data.content));
  }
}
