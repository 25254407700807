import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SqrsBroadcast} from './sqrs-broadcast';

@Component({
    selector: 'mir-sqrs-broadcast',
    templateUrl: './sqrs-broadcast.component.html',
    styleUrls: ['./sqrs-broadcast.component.css'],
    standalone: false
})
export class SqrsBroadcastComponent implements OnInit {

  @Input()
  private login: boolean;

  messages: SqrsBroadcast[] = [];

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.http.get('/api/sqrs/broadcast').subscribe((messages: SqrsBroadcast[]) => {
      if (this.login) {
        this.messages = messages;
      }
     else {
        this.messages = messages.filter(m => !m.loginOnly);
      }
    });
  }

}
