import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {smoothlyMenu} from '../../app.helpers';
import {Router} from '@angular/router';
import {User} from '../../model/user';
import {Auth2Service} from '../../auth/auth2.service';
import {Subscription} from 'rxjs';
import {Version} from '../../model/version';
import {MessageBellService} from '../../services/message-bell.service';
import {Announces} from 'app/plugin-announce/model/announces';

declare var jQuery: any;

@Component({
    selector: 'topnavbar',
    templateUrl: 'topnavbar.template.html',
    standalone: false
})
export class TopNavbarComponent implements OnInit, OnDestroy {
  @Input()
  user: User;

  @Input()
  version: Version;

  announcesSubscription: Subscription;

  announces: Announces;

  constructor(
    private auth2Service: Auth2Service,
    private router: Router,
    private bellService: MessageBellService
  ) {
  }

  ngOnInit(): void {
    if (this.version.isAuthorized('ANNOUNCE')) {
      this.auth2Service.connected().subscribe(connected => {
        if (connected) {
          this.announcesSubscription = this.bellService.announcesObservable.subscribe(
            announces => {
              this.announces = announces;
            }
          );
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.announcesSubscription) {
      this.announcesSubscription.unsubscribe();
    }
  }

  impersonateLogout() {
    this.auth2Service.impersonateLogout();
  }

  logout() {
    this.auth2Service.logout();
    this.router.navigate(['/login']);
  }

  toggleNavigation(): void {
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  notProd() {
    return this.version.getEnvionment() !== 'PR';
  }
}
