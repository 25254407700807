import {Component, OnInit} from '@angular/core';
import {SqrsBroadcast} from '../../../dashboards/sqrs-broadcast/sqrs-broadcast';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';

@Component({
    selector: 'mir-sqrs-message-edit-modal',
    templateUrl: './sqrs-message-edit-modal.component.html',
    styleUrls: ['./sqrs-message-edit-modal.component.css'],
    standalone: false
})
export class SqrsMessageEditModalComponent implements OnInit {

  onClose = new Subject();

  message: SqrsBroadcast;

  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit(): void {
  }

  onSaveMessage() {
    this.onClose.next(this.message);
  }

  addSdis(sdis: HTMLInputElement) {
    let value = +sdis.value; // l'input type est de type number
    sdis.value = null; // cleanup gui
    this.message.sdis.push(value);
  }

  rmSdis(sdis: number) {
    const idx = this.message.sdis.indexOf(sdis);
    if (idx > -1) {
      this.message.sdis.splice(idx, 1);
    }
  }
}
