import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';

import {ROUTES} from './app.routes';
import {AppComponent} from './app.component';
// App views
import {DashboardsModule} from './dashboards/dashboards.module';
import {LayoutsModule} from './layout/layouts/layouts.module';
import {VersionResolve} from './version.resolve.service';
import {VersionService} from './services/version.service';
import {SigninComponent} from './auth/signin/signin.component';
import {MirHttpErrorHandler} from './mir-http-error-handler';
import {AuthGuard} from './auth/auth-guard.service';
import {UserlistComponent} from './admin/users/userlist.component';
import {AdminUserService} from './services/admin-user.service';
import {ProfileService} from './services/profile.service';
import {Auth2Service} from './auth/auth2.service';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {JwtService} from './auth/jwt.service';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {BookingService} from './services/booking.service';
import {MsgrService} from './services/msgr.service';
import localeFr from '@angular/common/locales/fr-CH';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Angulartics2Module} from 'angulartics2';
import {MomentModule, TimeAgoPipe} from 'ngx-moment';
import {RoleOperationComponent} from './admin/permissions/role-operation/role-operation.component';
import {PermissionsService} from './admin/permissions/permissions.service';
import {AddUserToRoleComponent} from './admin/permissions/add-user-to-role/add-user-to-role.component';
import * as moment from 'moment';
import {MirAboutComponent} from './mir-about/mir-about.component';
import {TagsByUsersComponent} from './admin/tags/tag-list/tags-by-users.component';
import {TagManagerComponent} from './admin/tags/tag-manager/tag-manager.component';
import {ToastrModule} from 'ngx-toastr';
import {TagsViewComponent} from './admin/tags/tags-view/tags-view.component';
import {SqrsDashboardComponent} from './admin/sqrs-dashboard/sqrs-dashboard.component';
import {
  SqrsMessageEditModalComponent
} from './admin/sqrs-dashboard/sqrs-message-edit-modal/sqrs-message-edit-modal.component';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {
  MessageIconSelectorComponent
} from './admin/sqrs-dashboard/sqrs-message-edit-modal/message-icon-selector/message-icon-selector.component';
import {SharedModule} from './shared/shared.module';
import {RecaptchaModule} from "ng-recaptcha-2";

// App modules/components

registerLocaleData(localeFr);
moment.locale('fr');

// moment.locale('fr');

@NgModule({ declarations: [
        AppComponent,
        SigninComponent,
        UserlistComponent,
        RoleOperationComponent,
        AddUserToRoleComponent,
        MirAboutComponent,
        TagsByUsersComponent,
        TagManagerComponent,
        TagsViewComponent,
        SqrsDashboardComponent,
        SqrsMessageEditModalComponent,
        MessageIconSelectorComponent
    ],
    bootstrap: [AppComponent], imports: [SharedModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
        DashboardsModule,
        MomentModule,
        LayoutsModule,
        TabsModule.forRoot(),
        PaginationModule.forRoot(),
        SweetAlert2Module.forRoot(),
        ModalModule.forRoot(),
        ToastrModule.forRoot(),
        Angulartics2Module.forRoot(),
        RouterModule.forRoot(ROUTES),
        // ServiceWorkerModule.register('/ngsw-worker.js', {
        //     enabled: environment.production,
        //     registrationStrategy: 'registerImmediately'
        // }),
        TooltipModule,
        RecaptchaModule,
        ], providers: [
        TimeAgoPipe,
        Auth2Service,
        AuthGuard,
        AdminUserService,
        ProfileService,
        PermissionsService,
        VersionResolve,
        VersionService,
        BookingService,
        MsgrService,
        BsModalService,
        { provide: LOCALE_ID, useValue: 'fr-CH' },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        {
            provide: ErrorHandler,
            useClass: MirHttpErrorHandler
        },
        JwtService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtService,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
