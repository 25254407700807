<ng-container *ngIf="myConvocations.length > 0">
  <p>Vos prochaines convocations</p>

  <ul class="list-group">
    <li
      *ngFor="let c of myConvocations"
      [routerLink]="['convocations']"
      class="list-group-item"
      style="cursor:pointer;"
    >
      <small
      ><em class="fal fa-clock"></em>
        {{ c.ecawinEvent.dateStart | date: 'EEEE d MMM à HH:mm' }}
        <em class="fal fa-location-arrow"></em>&nbsp;{{
        c.ecawinEvent.location
        }}</small
      >
      <br/>
      <span
        [ngStyle]="{ 'text-decoration': c.absenceDate ? 'line-through' : '' }"
      >
        &nbsp;{{ c.ecawinEvent.summary }}
      </span>
      <span *ngIf="c.absenceDate" class="text-muted"
      >&nbsp;{{ c.absenceReason }}</span
      >
    </li>
  </ul>
</ng-container>
