<!-- Wrapper-->
<div id="wrapper" class="bg-eca">
  <!-- Left navigation bar -->
  <navigation [user]="user" [version]="version"></navigation>

  <!-- Main page wrapper -->
  <div id="page-wrapper" class="white-bg">
    <!-- Top navigation -->
    <topnavbar [user]="user" [version]="version"></topnavbar>

    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <footer [user]="user" [version]="version" style="padding-top: 50px"></footer>
  </div>
  <!-- End page wrapper-->
</div>
<!-- End wrapper-->
