import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TagService} from '../../../services/tag.service';
import {Tag} from '../../../model/tag';

@Component({
    selector: 'app-tag-manager',
    templateUrl: './tag-manager.component.html',
    styleUrls: ['./tag-manager.component.css'],
    standalone: false
})
export class TagManagerComponent implements OnInit {
  @Output()
  onSelect = new EventEmitter<Tag>();

  spinner = false;
  tags: Tag[];

  constructor(private tagService: TagService) {
  }

  ngOnInit(): void {
    this.reload();
  }

  createTag($event: any) {
    this.tagService.createTag($event).subscribe(tags => {
      this.reload();
    });
  }

  private reload() {
    this.spinner = true;

    this.tagService.getTags().subscribe(tags => {
      this.tags = tags;
      this.spinner = false;
    });
  }

  renameTag(tag: Tag, newName: string) {
    this.tagService.renameTag(tag, newName).subscribe(tags => {
      this.reload();
    });
  }

  removeTag(tag: Tag) {
    this.tagService.deleteTag(tag).subscribe(tags => {
      this.reload();
    });
  }

  showPeopleForTag(tag: Tag) {
    this.onSelect.emit(tag);
  }
}
