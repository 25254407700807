<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>
      Tags
    </h2>
  </div>
</div>

<div class="row m-t-sm">

  <div class="col-lg-12">
    <tabset #tabs>
      <tab heading="Grille">
        <app-tags-by-users
          [defaultUserFilter]="selectedUser"
          [selectedTag]="selectedTag"
          [users]="users"
          [tags]="tags"
        ></app-tags-by-users>
      </tab>
      <tab heading="Tags">
        <app-tag-manager (onSelect)="showPeople($event)"></app-tag-manager>
      </tab>
    </tabset>
  </div>
</div>
