import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TagService} from '../../../services/tag.service';
import {Tag} from '../../../model/tag';
import {UserWithTag} from '../../../model/user-with-tag';

@Component({
    selector: 'app-tags-by-users',
    templateUrl: './tags-by-users.component.html',
    styleUrls: ['./tags-by-users.component.css'],
    standalone: false
})
export class TagsByUsersComponent implements OnInit, OnChanges {
  spinner = false;

  sortDirection = 'DESC';
  sortColumn;

  @Input()
  defaultUserFilter: UserWithTag;

  @Input()
  users: UserWithTag[] = [];
  filtered: UserWithTag[] = [];

  @Input()
  selectedTag: Tag;

  @Input()
  tags: Tag[] = [];

  constructor(private tagService: TagService) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTag?.currentValue) {
      const tag = this.tags.find(
        value => value.id == changes.selectedTag?.currentValue.id
      );
      this.sort(tag);
    }
    if (changes.defaultUserFilter?.currentValue) {
      this.filtered.push(this.defaultUserFilter);
    }
    if (changes.users?.currentValue) {
      // if there is no default selection of users, show the full list
      if (!this.defaultUserFilter) {
        this.filtered = this.users;
      }
    }
  }

  toggle(user: UserWithTag, tag: Tag) {
    // It works here because <tag> is the same instance of the <tag> keys in the tagsMap object
    const userTag = user.tags.find(value => value.id === tag.id);
    userTag.enable = !userTag.enable;
    this.tagService.toggleTag(user, tag).subscribe(); // Update dabatase
  }

  sort(sortByTag: Tag) {
    this.selectedTag = sortByTag;
    this.spinner = true;
    this.sortDirection = this.sortDirection == 'ASC' ? 'DESC' : 'ASC';
    this.sortColumn = sortByTag;
    if (sortByTag) {
      // Show only users with this tag enabled
      this.filtered = this.users.filter(user =>
        user.tags.find(userTag => userTag.id === sortByTag.id && userTag.enable)
      );
    } else {
      this.filtered = this.users;
    }
    if (this.sortDirection === 'DESC') {
      this.filtered.reverse();
    }
    this.spinner = false;
  }

  removeFilter() {
    this.selectedTag = null;
    this.sort(null);
  }

  personFilter(value: string) {
    this.selectedTag = null;
    this.filtered = this.users.filter(
      value1 => value1.fullname.toUpperCase().indexOf(value.toUpperCase()) > -1
    );
  }
}
