import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProfileService {
  constructor(private http: HttpClient) {}

  updateUserDetails(profile: any) {
    return this.http.patch('/api/profile', profile);
  }
}
