import {Component, Input, OnInit} from '@angular/core';
import {Version} from '../../model/version';
import {User} from '../../model/user';

@Component({
    selector: 'mir-desktop-dashboard',
    templateUrl: './desktop-dashboard.component.html',
    styleUrls: ['./desktop-dashboard.component.css'],
    standalone: false
})
export class DesktopDashboardComponent implements OnInit {

  @Input()
  user: User;

  @Input()
  version: Version;

  constructor() {
  }

  ngOnInit(): void {
  }

}
