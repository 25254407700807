import {Component, OnInit, ViewChild} from '@angular/core';
import {Tag} from '../../../model/tag';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {TagService} from '../../../services/tag.service';
import {UserWithTag} from '../../../model/user-with-tag';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-tags-view',
    templateUrl: './tags-view.component.html',
    styleUrls: ['./tags-view.component.css'],
    standalone: false
})
export class TagsViewComponent implements OnInit {
  @ViewChild('tabs', { static: false }) tabs: TabsetComponent;

  // A user may have been selected from the user-list view
  selectedUser: UserWithTag;
  // A tag may have been selected from the tag manager tab
  selectedTag: Tag;
  tags: Tag[] = [];
  users: UserWithTag[] = [];

  constructor(
    private tagService: TagService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.tagService.getTags().subscribe(tags => (this.tags = tags));
    this.tagService.getUsers().subscribe(users => {
      this.users = users;
      const userId = this.activatedRoute.snapshot.paramMap.get('userId');
      if (userId) {
        this.selectedUser = this.users.find(user => user.id === +userId);
      }
    });
  }

  showPeople(tag: Tag) {
    this.selectedTag = tag;
    this.tabs.tabs[0].active = true;
  }
}
