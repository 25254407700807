<div class="row">
  <div class="col-lg-12">
    <p>
      Personnel {{ this.filtered.length }}
      <span *ngIf="selectedTag"> pour le tag {{ selectedTag.name }}</span>
      <button
        class="btn btn-default btn-sm"
        (click)="removeFilter()"
        *ngIf="selectedTag"
      >
        <em class="fal fa-times"></em>
      </button>
    </p>
    <input
      type="text"
      placeholder="Filtrer par personnel"
      class="form-control"
      (keyup)="personFilter(filter.value)"
      (keyup.enter)="personFilter(filter.value)"
      #filter
    />
    <table class="table table-bordered table-responsive" *ngIf="users">
      <thead>
      <tr>
        <th (click)="sort(null)">Voir tout</th>
        <th *ngFor="let tag of tags" (click)="sort(tag)">
          {{ tag.name }}
          <em
            *ngIf="sortColumn == tag"
            class="fal"
            [ngClass]="
                sortDirection == 'ASC' ? 'fa-caret-up' : 'fa-caret-down'
              "
          ></em>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr></tr>
      <tr *ngFor="let user of filtered">
        <td>{{ user.lastname }} {{ user.firstname }}</td>
        <ng-container *ngFor="let t of user.tags">
          <td>
            <button
              class="btn btn-primary"
              *ngIf="t.enable"
              (click)="toggle(user, t)"
            >
              <em class="fal fa-check"></em>
              {{ t.name }}
            </button>
            <button
              class="btn btn-default"
              *ngIf="!t.enable"
              (click)="toggle(user, t)"
            >
              {{ t.name }}
            </button>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>
</div>
