import {Component, Input} from '@angular/core';
import {SqrsBroadcast} from '../../../../dashboards/sqrs-broadcast/sqrs-broadcast';

@Component({
    selector: 'mir-message-icon-selector',
    templateUrl: './message-icon-selector.component.html',
    styleUrls: ['./message-icon-selector.component.css'],
    standalone: false
})
export class MessageIconSelectorComponent {

  @Input()
  icon: string;

  @Input() message: SqrsBroadcast;

}
