import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageBellService} from '../../services/message-bell.service';
import {Announces} from '../../plugin-announce/model/announces';
import {Subscription} from 'rxjs';

@Component({
    selector: 'mir-announces-widget',
    templateUrl: './announces-widget.component.html',
    styleUrls: ['./announces-widget.component.css'],
    standalone: false
})
export class AnnouncesWidgetComponent implements OnInit, OnDestroy {

  loading = false;
  announces: Announces;

  private sub: Subscription;

  constructor(private bellService: MessageBellService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.sub = this.bellService.announcesObservable.subscribe(announces => {
      this.announces = announces;
      this.loading = false;
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
