<div class="ibox no-margins" *ngIf="lite; else withBorders">
  <ul class="list-group">
    <ng-container *ngFor="let cal of today?.calendars">
      <li class="list-group-item" *ngFor="let e of cal.events">
        <em class="fas fa-car-side"></em>&nbsp;<a
        routerLink="/annuaire"
        [queryParams]="{ who: e.text }"
      >{{ e.text }}</a
      >
        <span class="badge badge-primary float-right">{{ cal.name }}</span>
      </li>
    </ng-container>
  </ul>
</div>

<ng-template #withBorders>
  <div class="ibox no-margins">
    <div class="ibox-content">
      <h4>
        <em class="fal fa-spin fa-spinner" *ngIf="spinner"></em> Aujourd'hui -
        <a (click)="toggleFollowing()">
          Prochainement
        </a>
      </h4>
      <ul class="list-group">
        <ng-container *ngFor="let cal of today?.calendars">
          <li class="list-group-item" *ngFor="let event of cal.events">
            <em class="fas fa-car-side"></em>&nbsp;
            <span class="badge badge-primary float-right">{{ cal.name }}</span>
            <a routerLink="/annuaire" [queryParams]="{ who: event.text }">{{
              event.text
              }}</a
            >&nbsp;<br/>
            <div class="float-right">
              <em class="far fa-arrow-to-right"></em>
              {{ event.endDate | date: 'EEEE d LLL' }}
            </div>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="showFollowing">
        <strong
        ><em class="fal fa-spin fa-spinner" *ngIf="spinner"></em>Semaines
          suivantes</strong
        >
        <ul class="list-group">
          <ng-container *ngFor="let g of following?.calendars">
            <li class="list-group-item" *ngFor="let e of g.events">
              <a routerLink="/annuaire" [queryParams]="{ who: e.text }">{{
                e.text
                }}</a>
              <span class="badge badge-primary float-right">{{ g.name }}</span>
              <br/>
              <div class="float-right">
                {{ e.startDate | date: 'dd.MM.yyyy' }}
                <em class="far fa-arrow-right"></em>
                {{ e.endDate | date: 'dd.MM.yyyy' }}
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
