import {Injectable} from '@angular/core';
import {User} from '../model/user';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class AdminUserService {
  constructor(private http: HttpClient) {}

  importEcawin() {
    return this.http.patch('/api/admin/users/importEcawin', null);
  }

  lock(user: User) {
    return this.http.put('/api/admin/users/' + user.id + '/lock', null);
  }

  unlock(user: User) {
    return this.http.put('/api/admin/users/' + user.id + '/unlock', null);
  }

  getUserDetails(userId: number) {
    return this.http.get('/api/admin/users/' + userId);
  }

  impersonate(user: User) {
    return this.http.get('/api/admin/impersonate/' + user.id);
  }

  editUser(user: User) {
    return this.http.put('/api/admin/users/' + user.id, user);
  }

  findDuplicates() {
    return this.http.get('/api/admin/users/duplicates').pipe(map((list:any) => list.map(data => new User(data))));
  }

  getUsersPage(
    filter: string,
    archives = false,
    page,
    size,
    sortBy,
    sortDirection
  ) {
    return this.http.get(
      '/api/admin/users/page?filter=' +
        filter +
        '&archives=' +
        archives +
        '&sort=' +
        sortBy +
        ',' +
        sortDirection +
        '&page=' +
        page +
        '&size=' +
        size
    );
  }

  createUser(newUser: any) {
    return this.http.post('/api/admin/users', newUser).pipe(map(data => new User(data)));
  }

  toggleLdap(user: User) {
    return this.http.put('/api/admin/users/' + user.id + '/ldap', null).pipe(map(data => new User(data)));
  }

  setUserPassword(user: User, newPassword: string) {
    return this.http.patch('/api/admin/users/' + user.id + '/password', newPassword).pipe(map(data => new User(data)));
  }
}
