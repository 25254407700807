import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {SqrsBroadcast} from '../../dashboards/sqrs-broadcast/sqrs-broadcast';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {SqrsMessageEditModalComponent} from './sqrs-message-edit-modal/sqrs-message-edit-modal.component';

import {TimeAgoPipe} from 'ngx-moment';


@Component({
    selector: 'mir-sqrs-dashboard',
    templateUrl: './sqrs-dashboard.component.html',
    styleUrls: ['./sqrs-dashboard.component.css'],
    standalone: false
})
export class SqrsDashboardComponent implements OnInit {

  private bsModalRef: BsModalRef;
  messages: SqrsBroadcast[] = [];

  ldap = false;
  recaptcha = false;

  error: any;

  constructor(private http: HttpClient, private toasterService: ToastrService, private modalService: BsModalService, private timeAgoPipe: TimeAgoPipe) {
  }

  ngOnInit(): void {
    this._doLoad();
  }

  sendMail() {
    this.http.put('/api/root/email-checker', null).subscribe(() => this.toasterService.success(null, '200 OK'), (error) => {
      this.error = error;
      this.toasterService.error(error.message, 'Erreur !');
    });
  }

  toggleMessage(msg: SqrsBroadcast) {
    this.http.put('/api/sqrs/message/' + msg.filename + '/toggle', null).subscribe(() => {
      this._doLoad();
    });
  }

  private _doLoad() {
    this.http.get('/api/sqrs/allMessages').subscribe((messages: SqrsBroadcast[]) => this.messages = messages);
    this.http.get('/api/sqrs/ldap').subscribe((ldap: boolean) => this.ldap = ldap);
    this.http.get('/api/sqrs/recaptcha').subscribe((recaptcha: boolean) => this.recaptcha = recaptcha);

  }

  showEditModal(message: SqrsBroadcast) {
    let copyOfMessage = { ...message }; // copy
    let options: ModalOptions<SqrsMessageEditModalComponent> = {
      backdrop: 'static',
      ignoreBackdropClick: true,
      keyboard: true,
      initialState: {
        message: copyOfMessage
      }
    };
    this.bsModalRef = this.modalService.show(
      SqrsMessageEditModalComponent,
      options
    );
    this.bsModalRef.content.onClose.subscribe(() => {
      this.http.put('/api/sqrs/message/' + message.filename, copyOfMessage).subscribe(() => {
        this._doLoad();
        this.toasterService.success('Message enregistré');
        this.bsModalRef.hide();
      });
    });
  }

  clearEcawinCaches() {
    this.http.patch('/api/root/ecawin-clear', null, { responseType: 'text' }).subscribe((lastUpdate: string) => {
      let text = this.timeAgoPipe.transform(lastUpdate);
      this.toasterService.success('Dernières données Ecawin: ' + text, 'Effacer caches Ecawin');
    }, (error) => {
      this.error = error;
      this.toasterService.error(error.message, 'Erreur !');
    });
  }

  toggleRecaptcha() {
    this.http.patch('/api/sqrs/recaptcha', null).subscribe((recaptcha: boolean) => this.recaptcha = recaptcha);
  }

  toggleLdap() {
    this.http.patch('/api/sqrs/ldap', null).subscribe((ldap: boolean) => this.ldap = ldap);

  }

  testLdap() {
    this.http.post('/api/sqrs/ldap', null).subscribe((ldap: boolean) => ldap ? this.toasterService.success("OK", "LDAP connexion") : this.toasterService.error("Pas bon", "LDAP connexion"), error => this.toasterService.error(error.error, "LDAP Erreur !"));
  }
}
