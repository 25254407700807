import {Component, OnInit} from '@angular/core';
import {detectBody} from '../../app.helpers';
import {VersionService} from '../../services/version.service';
import {ActivatedRoute} from '@angular/router';
import {Version} from '../../model/version';
import {User} from '../../model/user';
import {Auth2Service} from '../../auth/auth2.service';

declare var jQuery: any;

@Component({
    selector: 'basic',
    templateUrl: 'basicLayout.template.html',
    host: {
        '(window:resize)': 'onResize()'
    },
    providers: [VersionService],
    standalone: false
})
export class BasicLayoutComponent implements OnInit {
  version: Version;
  user: User = new User(null);

  constructor(
    private route: ActivatedRoute,
    private auth2Service: Auth2Service
  ) {}

  public onResize() {
    detectBody();
  }

  ngOnInit(): void {
    detectBody();
    this.route.data.subscribe(data => {
      this.version = new Version(data['version']);
    });

    this.auth2Service.user().subscribe(user => {
      this.user = user;
    });
  }
}
