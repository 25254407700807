<div class="modal-header">
  <h4 class="modal-title float-left">Message broadcast</h4>
  <button
    type="button"
    class="close float-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form">
    <div class="form-group">
      <label>Icone</label>
      <input class="form-control" id="icon" name="icon" hidden>
      <p>
        <mir-message-icon-selector icon="fa-info-square" [message]="message"></mir-message-icon-selector>
        <mir-message-icon-selector icon="fa-question-square" [message]="message"></mir-message-icon-selector>
        <mir-message-icon-selector icon="fa-times-square" [message]="message"></mir-message-icon-selector>
        <mir-message-icon-selector icon="fa-exclamation" [message]="message"></mir-message-icon-selector>
        <mir-message-icon-selector icon="fa-wrench" [message]="message"></mir-message-icon-selector>
        <mir-message-icon-selector icon="fa-fire" [message]="message"></mir-message-icon-selector>
      </p>
    </div>
    <div class="form-group">
      <label>SDIS</label>
      <p><span
        class="text-muted">Sans SDIS spécifié, le message est visible sur tous les Mir de du même environment.</span>
      </p>
      <badge *ngFor="let aSdis of message.sdis" class="badge mr-1">
        <button class="btn btn-sm" (click)="rmSdis(aSdis)">{{ aSdis}} <em class="fal fa-times"></em></button>
      </badge>
      <div class="input-group">
        <input type="number" class="form-control" #sdisToAdd/>
        <button class="btn btn-primary input-group-btn" (click)="addSdis(sdisToAdd)">Ajouter</button>
      </div>
    </div>
    <div class="form-group">
      <label>Criticité</label>
      <input class="form-control" [(ngModel)]="message.criticity" id="criticity" name="criticity">
    </div>
    <div class="form-group">
      <label>Date (depuis)</label>
      <input class="form-control" [(ngModel)]="message.dateFrom" id="dateFrom" name="dateFrom">
    </div>
    <div class="form-group">
      <label>Sujet</label>
      <input class="form-control" [(ngModel)]="message.subject" id="subject" name="subject">
    </div>
    <div class="form-group">
      <label>Message</label>
      <input class="form-control" [(ngModel)]="message.body" id="body" name="body">
    </div>

  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-primary float-right" (click)="onSaveMessage()"><em class="fal fa-check"></em> Enregistrer
  </button>
</div>
