<div class="sk-loading" *ngIf="loading">
  <div class="spiner-example">
    <div class="sk-spinner sk-spinner-wave">
      <div class="sk-rect1"></div>
      <div class="sk-rect2"></div>
      <div class="sk-rect3"></div>
      <div class="sk-rect4"></div>
      <div class="sk-rect5"></div>
    </div>
  </div>
</div>
<ng-container *ngIf="lite">
  <ul class="list-group" *ngIf="events?.length > 0; else empty">
    <li class="list-group-item" *ngFor="let e of events">
      <span class="text-capitalize">{{ e.text }}</span> &nbsp;<br />
      <span class="text-muted small">{{ e.details }}</span>  <span *ngIf="e.ecawinCode" class="text-muted small"> - {{ e.ecawinCode }}</span>  <br/>
      <span class="font-bold"
      >de {{ e.startDateDate | date: 'HH:mm' }} à
        {{ e.endDateDate | date: 'HH:mm' }}</span
      >
      <div class="row m-l-sm m-r-sm">
        <div class="col-md-12">
          <div class="float-right">
            <ng-container *ngIf="e.reservations">
              <button
                class="btn btn-default btn-sm "
                [routerLink]="['/booking/view', res.id]"
                *ngFor="let res of e.reservations"
              >
                Matériel <i class="far fa-book"></i>
              </button>
            </ng-container>
            <button
              class="btn btn-default btn-sm"
              *ngIf="e.participants.length > 0"
              (click)="e.showParticipants = !e.showParticipants"
            >
              {{e.participants.length}} Participants
              <em
                class="fal"
                [ngClass]="e.showParticipants ? 'fa-chevron-down' : 'fa-chevron-left'"
              ></em>
            </button>
          </div>
        </div>

        <div class="col-md-12" *ngIf="e.showParticipants">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let p of e.participants">
              <mir-ecawin-participant [participant]="p"></mir-ecawin-participant>
            </li>
          </ul>
        </div>

      </div>
    </li>
  </ul>
</ng-container>

<div class="ibox float-e-margins" *ngIf="!lite">
  <div class="ibox-content no-borders">
    <div>
      <h4>
        <i class="far fa-calendar-alt"></i> Aujourd'hui -
        <a [queryParams]="{ ecawin: true }" [routerLink]="['/booking/calendar']"
        >Calendrier complet</a
        >
      </h4>
      <ul class="list-group" *ngIf="events?.length > 0; else empty">
        <li class="list-group-item" *ngFor="let e of events">
          <span class="text-capitalize">{{ e.text }}</span> &nbsp;<br />
          <span class="text-muted small">{{ e.details }}</span> <span *ngIf="e.ecawinCode" class="text-muted small"> - {{ e.ecawinCode }}</span> <br/>
          <span class="font-bold"
          >de {{ e.startDateDate | date: 'HH:mm' }} à
            {{ e.endDateDate | date: 'HH:mm' }}</span
          >
          <div class="row m-l-sm m-r-sm">
            <div class="col-md-12 ">
              <div class="float-right">
                <ng-container *ngIf="e.reservations">
                  <button
                    class="btn btn-default btn-sm"
                    [routerLink]="['/booking/view', res.id]"
                    *ngFor="let res of e.reservations"
                  >
                    Matériel <i class="far fa-book"></i>
                  </button>
                </ng-container>
                <button
                  class="btn btn-default btn-sm"
                  *ngIf="e.participants"
                  (click)="e.showParticipants = !e.showParticipants"
                >
                  {{e.participants.length}} Participants
                  <i
                    class="fal"
                    [ngClass]="
                  e.showParticipants ? 'fa-chevron-up' : 'fa-chevron-down'
                "
                  ></i>
                </button>
              </div>
            </div>
            <div class="col-md-12" *ngIf="e.showParticipants">
                <ul class="list-group">
                  <li class="list-group-item borderless" *ngFor="let p of e.participants">
                    <mir-ecawin-participant [participant]="p"></mir-ecawin-participant>
                  </li>
                </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #empty>
  <p>Aucun événement SDIS aujourd'hui</p>
</ng-template>
