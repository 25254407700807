import {Component, Input, OnInit} from '@angular/core';
import {GoogleData} from '../google-data';
import {GoogleCalendarService} from '../google-calendar.service';

@Component({
    selector: 'mir-google-calendars-widget',
    templateUrl: './google-calendars-widget.component.html',
    styleUrls: ['./google-calendars-widget.component.css'],
    standalone: false
})
export class GoogleCalendarsWidgetComponent implements OnInit {
  spinner = false;

  @Input()
  lite: boolean = false;

  today: GoogleData;
  following: GoogleData;

  showFollowing = false;

  constructor(private gService: GoogleCalendarService) {
  }

  ngOnInit(): void {
    this.spinner = true;
    this.gService.getPiquets().subscribe(
      (data: GoogleData) => {
        this.today = data;
        this.spinner = false;
      },
      error => {
        this.spinner = false;
      }
    );
  }

  toggleFollowing() {
    this.showFollowing = !this.showFollowing;
    if (!this.following) {
      this.spinner = true;
      this.gService.getFollowing().subscribe(
        (data: GoogleData) => {
          this.following = data;
          this.spinner = false;
        },
        error => {
          this.spinner = false;
        }
      );
    }
  }
}
