<div class="row border-bottom white-bg dashboard-header">
  <div
    *ngIf="
      version?.isAuthorized('MATERIEL') && user.isAuthorized('OP_BOOKING_READ')
    "
    class="col-md-2 d-md-none"
  >
    <div class="widget navy-bg">
      <a (click)="showToday = !showToday" href="#">
        <div class="row">
          <div class="col-xs-4 m-l">
            <i class="fal fa-bullseye-pointer fa-3x" style="color: white;"></i>
          </div>
          <div class="col-xs-8 ml-auto m-r" style="color: white;">
            Aujourd'hui dans mon SDIS
          </div>
        </div>
      </a>
    </div>
    <ng-container *ngIf="showToday">
      <mir-google-calendars-widget
        *ngIf="version.isAuthorized('GOOGLECALENDAR')"
        [lite]="true"
      ></mir-google-calendars-widget>
      <app-today-ecawin [lite]="true"></app-today-ecawin>
    </ng-container>
  </div>

  <div *ngIf="version?.isAuthorized('ANNOUNCE')" class="col-md-2 d-md-none">
    <div class="widget" [ngClass]="announces?.myNotificationsUnread?.length == 0 ? 'navy-bg' : 'yellow-bg'">
      <a [routerLink]="['/annonce']">
        <div class="row">
          <div class="col-xs-4 m-l">
            <i
              class="fal fa-3x"
              [ngClass]="
                announces?.myNotificationsUnread.length > 0
                  ? 'fa-bell-on'
                  : 'fa-bell'
              "
              style="color: white;"
            ></i>
          </div>
          <div class="col-xs-8 m-r ml-auto" style="color: white;">
            <span>Annonce(s)</span>
            <h3
              *ngIf="announces?.myNotificationsUnread.length > 0"
              class="font-bold"
              style="color: white;"
            >
              {{ announces?.myNotificationsUnread?.length }} non lu(s)
            </h3>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div
    *ngIf="
      version?.isAuthorized('CONVOCATION') &&
      user.isAuthorized('OP_CONVOCATION')
    "
    class="col-md-2"
  >
    <div class="widget navy-bg">
      <a [routerLink]="['convocations']">
        <div class="row">
          <div class="col-xs-4 m-l">
            <i class="fal fa-inbox-in fa-3x" style="color: white;"></i>
          </div>
          <div class="col-xs-8 m-r ml-auto " style="color: white;">
            <span> Mes convocations </span>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div
    *ngIf="
      version?.isAuthorized('ECAWIN') && user.isAuthorized('OP_BOOKING_READ')
    "
    class="col-md-2"
  >
    <div class="widget navy-bg">
      <a [routerLink]="['booking/calendar']" [queryParams]="{ ecawin: true }">
        <div class="row">
          <div class="col-xs-4 m-l">
            <i class="fal fa-calendar-alt fa-3x" style="color: white;"></i>
          </div>
          <div class="col-xs-8 m-r ml-auto" style="color: white;">
            <span>Calendrier SDIS</span>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div
    *ngIf="
      version?.isAuthorized('FORMULAIRES') && user.isAuthorized('OP_FORM_READ')
    "
    class="col-md-2"
  >
    <div class="widget navy-bg">
      <a [routerLink]="['formulaires/absence']">
        <div class="row">
          <div class="col-xs-4 m-l">
            <i class="fal fa-bullhorn fa-3x" style="color: white;"></i>
          </div>
          <div class="col-xs-8 m-r ml-auto" style="color: white;">
            <span> Excuse / Absence </span>
<!--            <h2 class="font-bold text-right" style="color: white;">-->
<!--              <i class="fal fa-plus"></i>-->
<!--            </h2>-->
          </div>
        </div>
      </a>
    </div>
  </div>

  <div
    *ngIf="
      version?.isAuthorized('MATERIEL') && user.isAuthorized('OP_BOOKING_READ')
    "
    class="col-md-2"
  >
    <div class="widget navy-bg">
      <a [queryParams]="{'ecawin': true}" [routerLink]="['booking', 'calendar']">
        <div class="row">
          <div class="col-xs-4 m-l">
            <i class="fal fa-calendar-alt fa-3x" style="color: white;"></i>
          </div>
          <div class="col-xs-8 m-r ml-auto" style="color: white;">
            <span>Calendrier SDIS</span>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div
    *ngIf="
      version?.isAuthorized('ROULAGE') && user.isAuthorized('OP_BOOKING_CREATE')
    "
    class="col-md-2"
  >
    <div class="widget navy-bg">
      <a [routerLink]="['roulage', 'saisir-rapport']">
        <div class="row">
          <div class="col-xs-4 m-l">
            <i class="fal fa-truck fa-3x" style="color: white;"></i>
          </div>
          <div class="col-xs-8 m-r ml-auto" style="color: white;">
            <span>Rapport roulage</span>
<!--            <h2 class="font-bold text-right" style="color: white;">-->
<!--              <i class="fal fa-plus"></i>-->
<!--            </h2>-->
          </div>
        </div>
      </a>
    </div>
  </div>

  <div
    *ngIf="version?.isAuthorized('APR') && user.isAuthorized('OP_APR_READ')"
    class="col-md-2"
  >
    <div class="widget navy-bg">
      <a [routerLink]="['apr']">
        <div class="row">
          <div class="col-xs-4 m-l">
            <i class="fal fa-fire-extinguisher fa-3x" style="color: white;"></i>
          </div>
          <div class="col-xs-8 m-r ml-auto" style="color: white;">
            <span>Gestion APR </span>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
