<ng-container *ngIf="user">
  <div class="row white-bg">
    <div class="col-sm-9">
      <h1>{{ user.fullname }}</h1>
      <dl>
        <ng-container *ngFor="let role of user.permissions">
          <dd *ngIf="!role.startsWith('OP_')">
            <h3>
              <button class="btn btn-danger btn-sm" (click)="unassign(role)">
                <i class="fas fa-times"></i>
              </button>
              Rôle : {{ role }}
            </h3>
          </dd>
          <dd *ngIf="user.isRoot() && role.startsWith('OP_')">{{ role }}</dd>
        </ng-container>
      </dl>
      <div class="form-group">
        <label>Ajout du rôle</label>
        <div class="input-group">
          <select class="form-control" [(ngModel)]="newRole" *ngIf="allRoles">
            <option></option>
            <ng-container *ngFor="let role of availableRoles()">
              <option *ngIf="!user.hasRole(role)"  [value]="role.id">{{
                role.name
                }}</option>
            </ng-container>
          </select>
          <span class="input-group-btn">
            <button
              class="btn btn-primary"
              (click)="addRole()"
              [disabled]="!newRole"
            >
              <i class="far fa-check"></i> Ajouter
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
