import {Component, OnDestroy, OnInit} from '@angular/core';
// Import Chart.js library
import {User} from '../model/user';
import {Auth2Service} from '../auth/auth2.service';
import {ActivatedRoute} from '@angular/router';
import {Version} from '../model/version';
import {MessageBellService} from '../services/message-bell.service';

@Component({
    templateUrl: 'dashboard.template.html',
    styles: ['a:visited {color: white;}'],
    standalone: false
})
export class DashboardComponent implements OnInit, OnDestroy {
  public nav: any;
  public body: any;

  user: User;

  version: Version;

  private userSubscription;

  public constructor(
    private bellService: MessageBellService,
    private auth2Service: Auth2Service,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.auth2Service
      .user()
      .subscribe(theUser => (this.user = theUser));
    this.activatedRoute.parent.data.subscribe(data => {
      this.version = new Version(data['version']);
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }


  reload() {
    window.location.reload();
  }
}
