<div class="row white-bg">
  <div class="col-lg-3">
    <h2>Bonjour {{user.fullname}}</h2>
    <ng-container
      *ngIf="version?.isAuthorized('CONVOCATION') && user.isAuthorized('OP_CONVOCATION')"
    >
      <app-my-convocations></app-my-convocations>
    </ng-container>
    <ng-container
      *ngIf="version?.isAuthorized('MATERIEL') && user.isAuthorized('OP_BOOKING_READ')"
    >
      <app-my-bookings></app-my-bookings>
    </ng-container>
  </div>

  <div
    *ngIf="version?.isAuthorized('MSGR') && user.isAuthorized('OP_MSGR')"
    class="col-lg-3"
  >
    <app-last-msgr></app-last-msgr>
  </div>
  <div
    *ngIf="version?.isAuthorized('MATERIEL') && user.isAuthorized('OP_BOOKING_READ')"
    class="col-lg-3"
  >
    <mir-google-calendars-widget
      *ngIf="version?.isAuthorized('GOOGLECALENDAR')"
    ></mir-google-calendars-widget>
    <app-today-ecawin></app-today-ecawin>
  </div>
  <div
    *ngIf="user?.isAuthorized('ROLE_SAPEUR') && version?.isAuthorized('ANNOUNCE')"
    class="col-lg-3"
  >
    <mir-announces-widget></mir-announces-widget>
  </div>

</div>
