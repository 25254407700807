<div class="ibox float-e-margins">
  <div class="ibox-content no-borders">
    <div>
      <h4><a routerLink="/annonce">Annonces</a></h4>
      <ng-container *ngIf="announces?.myNotificationsUnread?.length > 0">
        <p><span class="badge badge-primary">{{announces?.myNotificationsUnread?.length}} Nouveau<span *ngIf="announces?.myNotificationsUnread?.length > 1">x</span> </span></p>
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let a of announces?.myNotificationsUnread"
          >
            <small>
              <em class="fal fa-clock"></em>&nbsp;{{
              a.startDate | date: 'dd.MM.yy'
              }}
              au {{ a.endDate | date: 'dd.MM.yy' }}
            </small>
            <br>
            <a [routerLink]="['/annonce', a.id]">
              <span style="word-break: break-all">{{ a.title }}</span>
            </a>

          </li>
        </ul>
        <hr/>
      </ng-container>
      <ng-container *ngIf="announces?.myNotificationsRead?.length > 0">
        <p><span class="badge badge-white">Déjà lus</span></p>
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let a of announces?.myNotificationsRead"
          >
            <small>
              <em class="fal fa-clock"></em>&nbsp;{{
              a.startDate | date: 'dd.MM.yy'
              }}
              au {{ a.endDate | date: 'dd.MM.yy' }}
            </small>
            <br>
            <span class="badge badge-warning"></span>
            <a [routerLink]="['/annonce', a.id]">
              <span style="word-break: break-all">{{ a.title }}</span>
            </a>

          </li>
        </ul>
      </ng-container>

    </div>
  </div>
</div>

<div class="sk-loading" *ngIf="loading">
  <div class="spiner-example">
    <div class="sk-spinner sk-spinner-wave">
      <div class="sk-rect1"></div>
      <div class="sk-rect2"></div>
      <div class="sk-rect3"></div>
      <div class="sk-rect4"></div>
      <div class="sk-rect5"></div>
    </div>
  </div>
</div>
