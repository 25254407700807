import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminUserService} from '../../services/admin-user.service';
import {User} from '../../model/user';
import {Router} from '@angular/router';
import {Auth2Service} from '../../auth/auth2.service';
import {ToastrService} from 'ngx-toastr';
import {Site} from '../../model/site';
import {SiteService} from '../../services/site.service';

@Component({
    selector: 'app-userlist',
    styleUrls: ['./userlist.component.css'],
    templateUrl: './userlist.component.html',
    styles: [],
    standalone: false
})
export class UserlistComponent implements OnInit, OnDestroy {
  users: User[];
  duplicates: User[];
  loadedUsers: User[];

  roles: any[];

  subscription;

  editRow: User;

  itemsPerPage = 30;
  totalItems = 0;
  smallnumPages = 0;
  currentPage = 1;
  sortColumn = 'lastname';
  sortDirection = 'ASC';

  filter: string = '';
  loggedUser: User;

  archives = false;

  sites: Site[];

  constructor(
    private router: Router,
    private adminUserService: AdminUserService,
    private toasterService: ToastrService,
    private auth2Service: Auth2Service,
    private siteService: SiteService
  ) {}

  ngOnInit() {
    this.load(this.currentPage);

    this.siteService.getSites().subscribe(sites => this.sites = sites);

    this.subscription = this.auth2Service
      .impersonated()
      .subscribe((isImpersonated: User) => {
        if (isImpersonated) {
          this.router.navigate(['/']);
          this.toasterService.success(
            'Impersonate',
            'Vous êtes maintenant: ' + isImpersonated.fullname
          );
        }
      });
    this.loggedUser = this.auth2Service.loggedUser;
    this.showDuplicates();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showDuplicates() {
    this.adminUserService.findDuplicates().subscribe(users => {
      this.duplicates = users;
    })
  }

  filterByLastname(filter) {
    this.filter = filter;
    this.load(this.currentPage);
  }

  importEcawin() {
    this.adminUserService
      .importEcawin()
      .subscribe(data =>
        this.toasterService.success(
          'Import ECAWIN',
          'Cela peut prendre quelques minutes'
        )
      );
  }

  nothing() {}

  impersonate(user: User) {
    this.auth2Service.impersonate(user);
  }

  lock(user: User) {
    this.adminUserService.lock(user).subscribe(data => {
      this.toasterService.success("L'utilisateur a été bloqué.");
      user.blocked = true;
    });
  }

  unlock(user: User) {
    this.adminUserService.unlock(user).subscribe(data => {
      this.toasterService.success("L'utilisateur a été débloqué.");
      user.blocked = false;
    });
  }

  save(user: User) {
    user.lastConnection = null;
    this.adminUserService.editUser(user).subscribe((editUser: User) => {
      this.editRow = null;
      let idx = this.users.indexOf(user);
      if (idx > -1) {
        this.users[idx] = new User(editUser);
      }
      this.toasterService.success('Personne modifiée');
    });
  }

  cancel() {
    this.editRow = null;
  }

  edit(user: User) {
    this.editRow = user;
  }

  createUser(nip: string) {
    let newUser: any = {};
    newUser.nip = nip
    this.adminUserService.createUser(newUser).subscribe((user: User) => {
      this.toasterService.success(
        'Utilisateur créé',
        'Veuillez compléter ses données. Mot de passe par défaut : BonjourSDIS_' + nip
      );
      this.users.unshift(user); // add as the first element, the one to be updated right now
      this.editRow = user;
    });
  }

  load(page: number) {
    this.adminUserService
      .getUsersPage(
        this.filter,
        this.archives,
        page - 1,
        this.itemsPerPage,
        this.sortColumn,
        this.sortDirection
      )
      .subscribe((data: any) => {
        this.loadedUsers = [];
        this.totalItems = data.totalElements;
        for (let u of data.content) {
          this.loadedUsers.push(new User(u));
        }
        this.users = this.loadedUsers;
      });
  }

  sortBy(column: string) {
    this.sortColumn = column;
    if (this.sortDirection == 'DESC') {
      this.sortDirection = 'ASC';
    } else {
      this.sortDirection = 'DESC';
    }
    this.load(this.currentPage);
  }

  pageChanged(event: any): void {
    this.load(event.page);
  }



  toggleArchives() {
    this.archives = !this.archives;
    this.load(this.currentPage);
  }

  compareSitesById(site1: Site, site2: Site)  {
    return site1?.id == site2?.id;
  }

  togglLdap(user: User) {
    this.adminUserService.toggleLdap(user).subscribe(newUser => {
      let index = this.users.indexOf(user);
      this.users[index] = newUser;
    });
  }

  updatePassword(user: User, newPassword: string) {
    if (newPassword) {
      this.adminUserService.setUserPassword(user, newPassword).subscribe((updatedUser) => {
        let index = this.users.indexOf(user);
        this.users[index] = updatedUser;
        this.toasterService.success("", "Mot de passe défini.")
      });
    }
    else {
      this.toasterService.warning("Mot de passe vide interdit", "Mot de passe non modifié.")

    }
    }

}
