import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {VersionService} from './services/version.service';
import {Observable} from 'rxjs';
import {Version} from './model/version';

@Injectable()
export class VersionResolve  {
  constructor(private versionService: VersionService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Version> {
    return this.versionService.getVersion();
  }
}
